import React, { useEffect } from 'react';
import TotalRevenue from "../Insights/TotalRevenue";
import { Table } from 'antd';
import Payout from "./Payout";
import CustomMap from "../../components/common/CustomMap";
import DailyRevenue from "./DailyRevenue";
import WeeklyTrips from "./WeeklyTrips";
import DailyDis from "./DailyDistance";
import GoMap from "../../components/common/GoMap";


const Dashboard = (props) => {

    return (
        <div>
            <h2 className='header'>Dashboard</h2>
            <div className='dashboard-container1'>

                <div className='div1'>
                    <TotalRevenue margin/>
                </div>
                <div className='div2'>
                    <Payout />
                </div>
            </div>

            <div className='dashboard-container2'>
                <div className='div1'>
                    <DailyRevenue />
                </div>
                <div className='div1 mid-margin'>
                    <WeeklyTrips />
                </div>
                <div className='div1' >
                    <DailyDis />
                </div>
            </div>
            <div style={{ padding: "30px", marginTop: "20px", background: 'white' }}>
                <GoMap data={{}} height='70' home/>
            </div>
        </div>
    )
}


export default Dashboard;