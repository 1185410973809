import React, { Component } from 'react'
import ReactDOM from 'react-dom';

const CustomIcon = ({ type, className }) => {
    return React.createElement(type, { className: className })

}

export default CustomIcon;




