import styled from "styled-components";
import { Button, Form, Input, Radio } from "antd";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import ReactSnackBar from "react-js-snackbar";
import { AiOutlineInfoCircle } from "react-icons/ai";
import PrimaryCard from "./PrimaryCard";
import InputAddress from "./AddressInput";
import SelectTerminal from "./SelectTerminal";
import { useRef } from "react";

const LocationForm = ({ setData, data, setCurrentStep, handleChange, setVisibleTerminalList, setTerminalOpener }) => {
  const [notificationState, setNotificationState] = useState({
    show: false,
    showing: false,
  });
  const terminalRef = useRef(null);
  const locationRef = useRef(null);
  const offlineRef = useRef(null);

  const show = () => {
    if (notificationState.showing) return;

    setNotificationState({ show: true, showing: true });
    setTimeout(() => {
      setNotificationState({ show: false, showing: false });
    }, 2000);
  };

  const handleContinue = () => {
    if (!data.startCoordinates.lat || !data.endCoordinates.lat || !data.startDate) {
      show();
      return;
    }
    setCurrentStep(2);
  };

  return (
    <StyledForm layout={"vertical"}>
      <ReactSnackBar Icon={<AiOutlineInfoCircle />} Show={notificationState.show} style={{ marginTop: "50px" }}>
        Fill and select all fields to continue!
      </ReactSnackBar>

      {data.cargo_type == "CONTAINER" && (
        <Form.Item label="Container number" required={false} className="input-item">
          <div>
            <Input name="container_number" value={data.container_number} onChange={handleChange} size={"large"} />
          </div>
        </Form.Item>
      )}

      <Form.Item
        label="Pick up location"
        // name="start_address"
        required={false}
        className="input-item"
      >
        <InputAddress data={data} setData={setData} name="startAddress" coordinates="startCoordinates" />
        {/* {data.startAddress.trim().length === 0 && ( */}
        {/* {data?.trip_category == "TERMINAL_LOCATION" && ( */}
        <SelectTerminal
          setVisibleTerminalList={setVisibleTerminalList}
          opener={"start"}
          setTerminalOpener={setTerminalOpener}
        />
        {/* )} */}
        {/* )} */}
      </Form.Item>

      <Form.Item
        label="Drop Off location"
        // name="end_address"
        required={false}
        className="input-item"
      >
        <InputAddress data={data} setData={setData} name="endAddress" coordinates="endCoordinates" />
        <SelectTerminal
          setVisibleTerminalList={setVisibleTerminalList}
          opener={"end"}
          setTerminalOpener={setTerminalOpener}
        />
      </Form.Item>

      {data?.is_container_owner !== "yes" && data?.cargo_type == "CONTAINER" && (
        <Form.Item
          label="Empties Drop off Address"
          // name="drop_off_address"
          required={false}
          className="input-item"
        >
          <InputAddress
            placeholder="Enter your drop off location"
            data={data}
            setData={setData}
            name="dropOffAddress"
            coordinates="dropOffCoordinates"
          />
        </Form.Item>
      )}

      <h2 className={"sub-title"}>Select Start Date and Time</h2>
      <DatePicker
        className={"date-picker"}
        selected={data.startDate}
        onChange={(date) => setData({ ...data, startDate: date })}
        timeInputLabel="Time:"
        dateFormat="MM/dd/yyyy h:mm aa"
        showTimeInput
      />

      <div className={"btn-container"}>
        <Button onClick={() => setCurrentStep(0)}>Back</Button>
        <Button type={"primary"} onClick={handleContinue}>
          Continue
        </Button>
      </div>
    </StyledForm>
  );
};

const StyledForm = styled(Form)`
  input {
    border-radius: 5px;
    height: 50px;
  }

  .btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
  }

  button {
    border-radius: 5px;
    width: 140px;
    height: 50px;
  }

  .sub-title {
    margin-bottom: 20px;
    color: #000;
    font-weight: 600;
    font-size: 19px;
  }

  .type-card-container {
    margin-bottom: 30px;
  }

  label {
    font-weight: bold;
    font-size: 18px;
    color: ${({ theme }) => theme?.colors?.secondary};
  }

  .date-picker {
    width: 100% !important;
    border-radius: 5px;
    height: 50px;
    //margin-bottom: 10px;
    margin-top: -10px;
    border: 1px solid #cacaca;
    padding: 0 10px;
    outline: none;
  }
`;

export default LocationForm;
