import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { loginConstraints } from "../helper/ValidationHelper";
import { reduxFunctions } from "../helper/ReduxHelper";
import { Button, Form, Input, Spin, Modal } from "antd";
import loadingIcon from "./common/loadingIcon";
import { Link } from "react-router-dom";
import "../styles/auth.css";
import styled from "styled-components";
const validate = require("validate.js");

const LoginForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ email: "", password: "" });
  const [loadingReset, setLoadingReset] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [resetEmail, setResetEmail] = useState("");

  const handleResetEmail = ({ target: input }) => {
    setResetEmail(input.value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleForgotPassword = async () => {
    setLoadingReset(true);
    await props
      .requestPasswordResetEmail(resetEmail)
      .then(async (res) => {
        setLoadingReset(false);
        setResetEmail("");
        toast.success("Email Sent successfully!");
        setIsModalVisible(false);
      })
      .catch((error) => {
        setLoadingReset(false);
        toast.error(error.error?.response?.data?.message);
        return error;
      });
  };

  useEffect(() => {
    validate.formatters.custom = function (errors) {
      if (errors.length > 0) {
        return errors;
      }
    };
    return () => {
      validate.formatters.custom = null;
    };
  }, []);

  const _startLoading = () => {
    setLoading(true);
  };

  const _stopLoading = () => {
    setLoading(false);
  };

  const _login = async () => {
    if (
      validate({ email: data.email, password: data.password }, loginConstraints, { format: "custom" }) !== undefined
    ) {
      return;
    } else {
      _startLoading();
      await props
        .loginUser(data.email, data.password)
        .then(async (res) => {
          _stopLoading();
          const userDetail = res?.payload?.data?.user;

          if (userDetail?.user_type === "regular") {
            localStorage.removeItem("access_token");
            localStorage.removeItem("user");
            localStorage.removeItem("company");
            throw Error("Incorrect username or password");
          } else {
            toast.success(`Welcome back ${userDetail?.given_name}`);
            _stopLoading();
            props.handleLogin();
          }
        })
        .catch((error) => {
          toast.error("Incorrect username or password!");
          _stopLoading();
          return error;
        });
    }
  };

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  return (
    <Container>
      <div className="">
        <div style={{ marginBottom: "20px" }}>
          <h2>Sign In</h2>
          <p className="">
            New to Go Truck? <Link to="/register_company">Create an account</Link>
          </p>
        </div>

        <Form layout="vertical" name="login-form" onFinish={_login}>
          <Form.Item
            className="label"
            label="Email Address"
            name="email"
            rules={[
              {
                message: "Please input your email",
              },
              {
                type: "email",
                message: "Please enter a valid email!",
              },
            ]}
          >
            <Input placeholder="Email" className="input" name="email" value={data.email} onChange={handleChange} />
          </Form.Item>

          <Form.Item label="Password" className="label">
            <Input.Password
              placeholder="Password"
              className="input"
              name="password"
              value={data.password}
              onChange={handleChange}
            />
          </Form.Item>
          <div className="forgot-pass">
            <a onClick={showModal}>Forgot Password?</a>
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit" block className="btn">
              {loading ? <Spin indicator={loadingIcon} /> : <span>Sign In</span>}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <>
        <Modal
          title="Receive link to Reset Password?"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel} className="all-button">
              Cancel
            </Button>,
            <Button key="submit" loading={loading} onClick={handleForgotPassword} type="primary" className="all-button">
              {loadingReset ? <Spin indicator={loadingIcon} /> : <span>Send</span>}
            </Button>,
          ]}
        >
          <Form
            layout="vertical"
            name="reset-password-form"
            // onFinish={}
          >
            <Form.Item label="Email" className="label">
              <Input
                placeholder="email"
                className="input"
                name="email"
                value={resetEmail}
                onChange={handleResetEmail}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    </Container>
  );
};

const Container = styled.div`
  a,
  p {
    font-size: 14px;
  }

  .input {
    padding: 8px 12px;
    background: #fff;
    border-radius: 8px;
  }

  .forgot-pass {
    text-align: right;
    margin: -16px 0 20px 0;

    a {
      font-size: 14px;
    }
  }

  .btn {
    padding: 12px;
    height: 3rem;
    border-radius: 8px;
  }
`;

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
