import React, { useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { reduxFunctions } from "../helper/ReduxHelper";
import { Button, Form, Col, Input, Spin } from "antd";
import loadingIcon from "./common/loadingIcon";
import "../styles/auth.css";
import styled from "styled-components";
import { Link } from "react-router-dom";

const ResetPasswordForm = (props) => {
  const [data, setData] = useState({ password: "", confirmPassword: "" });
  const [loading, setLoading] = useState(false);

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const _startLoading = () => {
    setLoading(true);
  };

  const _stopLoading = () => {
    setLoading(false);
  };

  const handlePasswordReset = async () => {
    if (data.password.length === 0 || data.confirmPassword.length === 0) {
      toast.error("Please enter the new password");
      return;
    }

    if (data.password === data.confirmPassword) {
      _startLoading();
      await props
        .resetPassword(data.password, props.token)
        .then((res) => {
          toast.success("Password reset successful");
          _stopLoading();
          window.location.replace("/login");
          return;
        })
        .catch((error) => {
          _stopLoading();
          toast.error("Error resetting password");
          return error;
        });
    } else {
      toast.error("Entered passwords are not the same");
    }
  };

  return (
    <Container>
      <div className="inner">
        <div style={{ marginBottom: "20px" }}>
          <h2>Reset Password</h2>
          <p className="p1">
            Remembered your password? <Link to="/login"> Sign In</Link>
          </p>
        </div>

        <Form layout="vertical" name="reset-password-form">
          <Form.Item className="label" label="Password">
            <Input.Password
              placeholder="Password"
              className="input"
              name="password"
              value={data.password}
              onChange={handleChange}
            />
          </Form.Item>

          <Form.Item label="Confirm Password" className="label">
            <Input.Password
              placeholder="Confirm Password"
              className="input"
              name="confirmPassword"
              value={data.confirmPassword}
              onChange={handleChange}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block className="btn" onClick={handlePasswordReset}>
              {loading ? <Spin indicator={loadingIcon} /> : <span>Reset Password</span>}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Container>
  );
};

const Container = styled.div`
  a,
  p {
    font-size: 14px;
  }

  .input {
    padding: 8px 12px;
    background: #fff;
    border-radius: 8px;
  }

  .check {
    display: flex;
    gap: 10px;
  }

  .btn {
    margin-top: 20px;
    padding: 12px;
    height: 3rem;
    border-radius: 8px;
  }
`;

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
