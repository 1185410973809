const validate = require("validate.js");


export var userConstraints = {
    family_name:{
        presence: true,
    },
    given_name:{
        presence: true,
    },
    email:{
        presence: true,
        email: true
    },
    phone_number:{
        presence: true
    }
};

export var signUpConstraints = {
    family_name:{
        presence: true,
    },
    given_name:{
        presence: true,
    },
    email:{
        presence: true,
        email: true
    },
    phone_number:{
        presence: true
    },
    password: {
        presence: true,
        length: {
            minimum: 6,
            message: "must be at least 6 characters"
        }
    },
    confirm_password: {
        equality: "password"
    }
};

export var loginConstraints = {
    email:{
        presence: true,
        email: true
    },
    password: {
        presence: true,
    }
};

export var tripConstraints = {
    start_time:{
        presence: true,
    },
    start_address:{
        presence: true,
    },
    item_size:{
        presence: true,
    },
    container_number:{
        presence: true
    },
    end_address:{
        presence: true
    }
};

