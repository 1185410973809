import mocker from "mocker-data-generator";

const trip = {
  // distance: {
  //     faker: 'random.number({"min": 1, "max": 100})'
  // },
  //  driver: {
  //     email: {
  //         faker: "internet.email"
  //     },
  //      phone_number: {
  //          faker: "phone.phoneNumber"
  //      },
  //      full_name: {
  //          faker: 'name.findName'
  //      }
  //  },
  //  end_address: {
  //      faker: 'address.streetAddress'
  //  },
  //  end_time: {
  //     faker: 'date.recent'
  //  },
  //  item_size: {
  //     faker: 'random.number({"min": 1, "max": 100})'
  //  },
  //  price: {
  //      faker: 'random.number({"min": 1, "max": 100000})'
  //  },
  //  start_address: {
  //      faker: 'address.streetAddress'
  //  },
  //  start_time: {
  //      faker: 'date.recent'.valueOf()
  //  },
  //  _id: {
  //      chance: 'guid'
  //  }
};

export const { trips } = mocker().schema("trips", trip, 10).buildSync();
