import React, { useEffect, useState } from "react";
import { Button, Form, Input, Spin } from "antd";
import "../styles/main.css";
import { reduxFunctions } from "../helper/ReduxHelper";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ImageUpload from "../components/common/ImageUpload";
import loadingIcon from "../components/common/loadingIcon";
import { authenticatedCompany } from "../helper/AuthHelper";

const CompanyProfile = (props) => {
  const companyDetails = authenticatedCompany();
  const [data, setData] = useState({
    name: "",
    business_number: "",
    email: "",
    head_office_address: "",
    profile_picture: {},
    ...companyDetails,
  });
  const [loadingImage, setLoadingImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [s3ImageUrl, sets3ImageUrl] = useState(null);
  const [imageContentType, setImageContentType] = useState(null);
  const [fileList, setFileList] = useState([]);

  const handleChange = ({ currentTarget: input }) => {
    let newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  useEffect(() => {
    if (data.profile_picture) {
      setImageUrl(data.profile_picture.original);
      setFileList([{ uid: "current", status: "done", url: data.profile_picture.original }]);
    }
  }, []);

  const handleSave = async () => {
    setLoading(true);
    const newData = { ...data };
    if (imageUrl) {
      newData.profile_picture = {
        original: imageUrl,
      };
    }
    await props
      .updateCompany(newData)
      .then(async (res) => {
        toast.success("Company updated successfully");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Error saving company!");
      });
  };

  return (
    <div>
      <h2 className="header">Profile</h2>
      <div className="profile-container">
        <div className="image-upload">
          <ImageUpload
            data={data}
            loadingImage={loadingImage}
            uploadUrl={uploadUrl}
            fileList={fileList}
            imageUrl={imageUrl}
            setUploadUrl={setUploadUrl}
            sets3ImageUrl={sets3ImageUrl}
            setImageUrl={setImageUrl}
            setFileList={setFileList}
            setLoadingImage={setLoadingImage}
            s3ImageUrl={s3ImageUrl}
            listType="picture-card"
            button={false}
          />
        </div>

        <Form layout="vertical" name="login-form" onFinish={handleSave} className="profile-form-container">
          <Form.Item label="Company Name" className="label">
            <Input
              placeholder=""
              className="input"
              name="name"
              value={data.name}
              onChange={handleChange}
              size={"large"}
            />
          </Form.Item>

          <Form.Item label="Business Number" className="label">
            <Input
              placeholder=""
              className="input"
              name="business_number"
              value={data.business_number}
              onChange={handleChange}
              size={"large"}
            />
          </Form.Item>

          <Form.Item
            className="label"
            label="Email"
            rules={[
              {
                message: "Please input your email",
              },
              {
                type: "email",
                message: "Please enter a validate email!",
              },
            ]}
          >
            <Input
              placeholder="Email"
              className="input"
              name="email"
              value={data.email}
              onChange={handleChange}
              size={"large"}
            />
          </Form.Item>

          <Form.Item label="Head Office" className="label">
            <Input
              placeholder=""
              className="input"
              name="head_office_address"
              value={data.head_office_address}
              onChange={handleChange}
              size={"large"}
            />
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" block className="all-button" type="primary" size={"large"}>
              {loading ? <Spin indicator={loadingIcon} /> : <span>Save</span>}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
const mapStateToProps = ({ user, company, imageToken }) => ({ user, company, imageToken });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile);
