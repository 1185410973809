import moment from 'moment';

export const pastTripData = [
    {
        id: '001',
        details: 'Trip from 2 alexander Rd. Ikoyi to 28 Marina, Lagos Island',
        amount: '#30,500.00',
        dateFormat: moment('2021-02-01').format('LL'),
        date: '2021-02-01',
        status: 'Paid'
    },
    {
        id: '002',
        details: 'Trip from 2 alexander Rd. Ikoyi to 28 Marina, Lagos Island',
        amount: '#30,500.00',
        dateFormat: moment('2020-07-05').format('LL'),
        date: '2020-07-05',
        status: 'Paid'
    },
    {
        id: '003',
        details: 'Ride from 2 alexander Rd. Ikoyi to 28 Marina, Lagos Island',
        amount: '#30,500.00',
        dateFormat: moment('2021-02-05').format('LL'),
        date: '2021-02-05',
        status: 'Paid'
    },
    {
        id: '004',
        details: 'Move from 2 alexander Rd. Ikoyi to 28 Marina, Lagos Island',
        amount: '#30,500.00',
        dateFormat: moment('2021-01-05').format('LL'),
        date: '2021-01-05',
        status: 'Paid'
    },
    {
        id: '005',
        details: 'Trip from 109, Ibidun street, Surulere, Lagos',
        amount: '#30,500.00',
        dateFormat: moment('2019-05-05').format('LL'),
        date: '2019-05-05',
        status: 'Paid'
    },
    {
        id: '006',
        details: 'Trip from 2 alexander Rd. Ikoyi to 28 Marina, Lagos Island',
        amount: '#30,500.00',
        dateFormat: moment('2020-09-05').format('LL'),
        date: '2020-09-05',
        status: 'Paid'
    },
    {
        id: '007',
        details: 'Trip from 2 alexander Rd. Ikoyi to 28 Marina, Lagos Island',
        amount: '#30,500.00',
        dateFormat: moment('2018-02-05').format('LL'),
        date:'2018-02-05',
        status: 'Paid'
    },
    {
        id: '008',
        details: 'Trip from 2 alexander Rd. Ikoyi to 28 Marina, Lagos Island',
        amount: '#30,500.00',
        dateFormat: moment('2020-02-05').format('LL'),
        date: '2020-02-05',
        status: 'Paid'
    },
    {
        id: '009',
        details: 'Ride from 2 alexander Rd. Ikoyi to 28 Marina, Lagos Island',
        amount: '#30,500.00',
        dateFormat: moment('2021-10-05').format('LL'),
        date: '2021-10-05',
        status: 'Paid'
    },
    {
        id: '010',
        details: 'Move from 2 alexander Rd. Ikoyi to 28 Marina, Lagos Island',
        amount: '#30,500.00',
        dateFormat: moment('2019-05-05').format('LL'),
        date: '2019-05-05',
        status: 'Paid'
    },
    {
        id: '011',
        details: 'Trip from 109, Ibidun street, Surulere, Lagos',
        amount: '#30,500.00',
        dateFormat: moment('2020-03-05').format('LL'),
        date: '2020-03-05',
        status: 'Paid'
    },
    {
        id: '012',
        details: 'Trip from 2 alexander Rd. Ikoyi to 28 Marina, Lagos Island',
        amount: '#30,500.00',
        dateFormat: moment('2020-02-02').format('LL'),
        date:'2020-02-02',
        status: 'Paid'
    },
];
