import {Badge} from "antd";
import { Avatar, Image, Button } from 'antd';
import {RightOutlined} from "@ant-design/icons";
import React from "react";
import {Link} from "react-router-dom";
import empty from '../assets/empty.jpeg'
import obj from "../constants/Colors";
import View from '../assets/svgs/view.svg';



export const pastTripColumns = [
    // {
    //     title: 'ID',
    //     dataIndex: 'id',
    //     key: 'id',
    //     // responsive: ['md'],
    //     render: text => <Link to={`/app/trip-summary/${text}`}>{text}</Link>,
    //
    // },
    {
        title: 'Details',
        dataIndex: 'details',
        key: 'details',
        // responsive: ['md'],
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        responsive: ['md'],
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        responsive: ['md'],
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        // render: text => text === 'unpaid' ? <Badge status="warning" text={text} size={"small"}/> : <Badge status="success" text={text} size={"small"}/>,
        // responsive: ['md'],
    },
];

export const paymentHistoryColumns = [{
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        // responsive: ['md'],
    },
    {
        title: 'Trips',
        dataIndex: 'trips',
        key: 'trip',
        // responsive: ['md'],
    },
    {
        title: 'Gross Total',
        dataIndex: 'gross',
        key: 'gross',
        responsive: ['md'],
    },
    {
        title: 'Fees',
        dataIndex: 'fees',
        key: 'fees',
        responsive: ['md'],
    },
    {
        title: 'Net Total',
        dataIndex: 'total',
        key: 'total',
        responsive: ['md'],
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        // render: text => text === 'Pending' ? <Badge status="warning" text={text} /> : <Badge status="success" text={text} />,
// responsive: ['md'],
}]

export const driverListColumns = [
    {
        title: '',
        dataIndex: 'image',
        key: 'image',
        // responsive: ['md'],
        render: text => <Avatar shape="square"
                                src={text ? <Image src={text} /> :
                                    <Image src={empty}/> }
        />
    },
    {
        title: 'Full name',
        dataIndex: 'full_name',
        key: 'full_name',
        // responsive: ['md'],
    },
    {
        title: 'Phone number',
        dataIndex: 'phone_number',
        key: 'phone_number',
        responsive: ['md'],
    },
    {
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email',
        responsive: ['md'],
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        // responsive: ['md'],
    },
    {
        title: '',
        dataIndex: 'arrow',
        key: 'arrow',
        render: text => <RightOutlined />
        // responsive: ['md'],
    },
]

export const availableDriversColumn = [
    {
        title: '',
        dataIndex: 'image',
        key: 'image',
        // responsive: ['md'],
        render: text => <Avatar shape="square"
                                src={text ? <Image src={text} /> :
                                    <Image src={empty}/> }
        />
    },
    {
        title: 'Full name',
        dataIndex: 'full_name',
        key: 'full_name',
        // responsive: ['md'],
    },
    {
        title: 'Phone number',
        dataIndex: 'phone_number',
        key: 'phone_number',
        responsive: ['md'],
    },
    {
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email',
        responsive: ['md'],
    },
    {
        title: '',
        dataIndex: 'assign_truck',
        key: 'assign_truck',
        render: text => <Button className='all-button' type='primary' size={"large"}>Assign</Button>

    }
]

export const vehicleListColumns = [
    {
        title: '',
        dataIndex: 'image',
        key: 'image',
        // responsive: ['md'],
        render: text => <Avatar shape="square"
                                src={text ? <Image src={text} /> :
                                    <Image src={empty}/> }
        />
    },
    {
        title: 'Make',
        dataIndex: 'make',
        key: 'make',
        // responsive: ['md'],
    },
    {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
        responsive: ['md'],
    },
    {
        title: 'Plate Number',
        dataIndex: 'plate_number',
        key: 'plate_number',
        responsive: ['md'],
    },
    {
        title: 'Color',
        dataIndex: 'color',
        key: 'color',
        // responsive: ['md'],
    },
    {
        title: 'Tracker',
        dataIndex: 'tracker',
        key: 'tracker',
        // responsive: ['md'],
    },
    {
        title: '',
        dataIndex: '_id',
        key: '_id',
        render: text => <Link to={`/app/vehicle/${text}`}><div style={{width: '30px', height: '15px'}}>
            <img style={{width: '100%', height: '100%'}} src={View} alt='view' />
        </div></Link>
        // responsive: ['md'],
    },
]

export const payoutColumns = [
    {
        title: 'Payout Period',
        dataIndex: 'payout_period',
        key: 'payout_period',
        // responsive: ['md'],
    },
    {
        title: 'Trips',
        dataIndex: 'trips',
        key: 'trips',
        responsive: ['md'],
    },
    {
        title: 'Distance',
        dataIndex: 'distance',
        key: 'distance',
        responsive: ['md'],
    },
    {
        title: 'Gross Total',
        dataIndex: 'gross',
        key: 'gross',
        responsive: ['md'],
    },
    {
        title: 'Fees',
        dataIndex: 'fees',
        key: 'fees',
        responsive: ['md'],
    },
    {
        title: 'Net Total',
        dataIndex: 'total',
        key: 'total',
        // responsive: ['md'],
    },
    {
        title: 'Bank account',
        dataIndex: 'bank_account',
        key: 'bank_account',
        // responsive: ['md'],
    },
];

export const paymentHistoryCol = [
    // {
    //     title: 'ID',
    //     dataIndex: 'id',
    //     key: 'id',
    //     // responsive: ['md'],
    // },
    {
        title: 'Trip details',
        dataIndex: 'details',
        key: 'details',
        // responsive: ['md'],
    },
    {
        title: 'Distance',
        dataIndex: 'distance',
        key: 'distance',
        responsive: ['md'],
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        responsive: ['md'],
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        // responsive: ['md'],
    },
];

export const paymentHistoryColCompany = [
    // {
    //     title: 'ID',
    //     dataIndex: 'id',
    //     key: 'id',
    //     // responsive: ['md'],
    // },
    {
        title: 'Trip details',
        dataIndex: 'details',
        key: 'details',
        // responsive: ['md'],
    },
    {
        title: 'Distance',
        dataIndex: 'distance',
        key: 'distance',
        responsive: ['md'],
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        responsive: ['md'],
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        // responsive: ['md'],
    },
    {
        title: 'Driver',
        dataIndex: 'driver',
        key: 'driver'
    }

];

export const billingTableColumn = [
    {
        title: '',
        dataIndex: 'radio',
        key: 'radio',
        // responsive: ['md'],
    },
    {
        title: '',
        dataIndex: 'image',
        key: 'image',
        // responsive: ['md'],
        render: text => <Avatar shape="square" src={<Image src={text} />} />
    },
    {
        title: 'Bank Name',
        dataIndex: 'bank_name',
        key: 'bank_name',
        responsive: ['md'],
    },
    {
        title: 'Account Name',
        dataIndex: 'account_name',
        key: 'account_name',
        responsive: ['md'],
    },
    {
        title: '',
        dataIndex: 'badge',
        key: 'badge',
        // responsive: ['md'],
    },
    {
        title: '',
        dataIndex: 'delete',
        key: 'delete',
        // responsive: ['md'],
    }
]
