import React, {useState, useEffect} from 'react';
import {Table, Button, Pagination, DatePicker, InputNumber} from 'antd';
import Input from '../components/common/Input'
import moment from 'moment';
import Empty from '../assets/svgs/empty.svg'
import {SearchOutlined, SwapRightOutlined} from "@ant-design/icons";
import Select from '../components/common/Select'
import { paymentHistoryColumns, payoutColumns } from '../configs/tableConfig'
import { payoutData } from "../services/fakePaymentHis";
import { paginate } from '../utils/paginate';
import { paymentHistoryData } from '../services/fakePaymentHistory'
import { connect } from 'react-redux';
import { reduxFunctions} from "../helper/ReduxHelper";
import '../styles/Card.css';
import { CSVLink, CSVDownload } from "react-csv";
import Card from "../components/common/Card";
import { formatPayoutState } from '../helper/PayoutHelper';
import currency from "currency.js";
import obj from "../constants/Colors";
import {toast} from "react-toastify";

const { RangePicker } = DatePicker;

const PayoutScreen = (props) => {
    const [ payout, setPayout] = useState([])
    const [ data, setData] = useState(props.payouts.map(payout => {
        const newPayout = {
            id: payout._id,
            amount: payout.amount,
            date: moment(payout.end_date).format('DD MMMM YYYY'),
            payout_period: `${moment(payout.start_date).format('DD MMMM YYYY')} - ${moment(payout.end_date).format('DD MMMM YYYY')}`,
            end_date: moment(payout.end_date).format('DD MMMM YYYY'),
            distance: `${payout.distance}KM`,
            trips: payout.no_of_trips,
            gross: currency(payout.amount).format({symbol: '₦'}),
            fees:  currency(payout.gotruck_share).format({symbol: '₦'}),
            total: currency(payout.driver_share).format({symbol: '₦'}),
            totalCheck: payout.driver_share,
            status: formatPayoutState(payout.status),
            // bank_account:  `***${payout.recipient_bank.account_number.slice(7)}(NGN)`
            bank_account: payout.recipient_bank !== null ? `***${payout.recipient_bank.account_number.slice(7)}(NGN)`:  "*****"
        }
        return newPayout
    }))
    const [status, setStatus] = useState('All')
    const [displayedData, setDisplayedData] = useState([]);
    const [searchInput, setSearchInput] = useState('')
    const [pageNumber, setPageNumber] = useState(1);
    const [startPrice, setStartPrice] = useState(null);
    const [endPrice, setEndPrice] = useState(null);



    const getData = async () => {
        await props.getPayouts().then(res => {
            const newData = res.payload.data.payouts.map(payout => {
                const newPayout = {
                    id: payout._id,
                    amount: payout.amount,
                    date: moment(payout.end_date).format('DD MMMM YYYY'),
                    distance: `${payout.distance}KM`,
                    payout_period: `${moment(payout.start_date).format('DD MMMM YYYY')} - ${moment(payout.end_date).format('DD MMMM YYYY')}`,
                    trips: payout.no_of_trips,
                    gross: currency(payout.amount).format({symbol: '₦'}),
                    fees: currency(payout.gotruck_share).format({symbol: '₦'}),
                    total: currency(payout.driver_share).format({symbol: '₦'}),
                    totalCheck: payout.driver_share,
                    status: formatPayoutState(payout.status),
                    bank_account: `***${payout.recipient_bank.account_number.slice(7)}(NGN)`
                }

                return newPayout
            });
            setData(newData)
        }).catch(error => {
             // toast.error('Error getting payouts data!')
            return error;
        })
    }

    useEffect(getData, []);



    const handlePageChange = page => {
        setPageNumber(page);
    };

    useEffect(()=> {
           setDisplayedData(data);
           const lastItem = data[data.length - 1];
           const arr = []
           arr.push(lastItem)
           data.length === 0 ? setPayout([]) : setPayout(arr);
    }, [data])



    useEffect(() => {
        const newData = data.filter(row => row.totalCheck >= startPrice && row.totalCheck <= endPrice);
        if(startPrice !== null || endPrice !== null ) {
            setDisplayedData(newData)
            setPageNumber(1)
        }
        else{
            setDisplayedData(data);
        }

        return ()=> {
            setDisplayedData(data);
        }
        }, [endPrice])


    const formattedData = data.map(item => {
        return {
            id: item.id,
            date: item.date,
            distance: item.distance,
            payout_period: item.payout_period,
            trips: item.trips,
            gross: item.gross,
            fees: item.fees,
            total: item.total,
            bank_account: item.bank_account
        }
    })

    const headers = [
        {label: 'ID', key: 'id'},
        {label: 'Date', key: 'date'},
        {label: 'Total Distance covered', key: 'distance'},
        {label: 'Payout Period', key: 'payout_period'},
        {label: 'Trips', key: 'trips'},
        {label: 'Gross Total', key: 'gross'},
        {label: 'Fees', key: 'fees'},
        {label: 'Net Total', key: 'total'},
        {label: 'Bank Account', key: 'bank_account'},
    ]

    const csvReport = {
        filename: 'Payout.csv',
        headers: headers,
        data: formattedData
    }

    const clearFilter = () => {
        setEndPrice(null);
        setStartPrice(null);
        setDisplayedData(data);
    }



    const handleDateChange = (date) => {
        if(date){
            const newData = data.filter(row => moment(row.date).diff(moment(date[0]._d), 'days') > 0 && moment(row.date).diff(moment(date[1]._d), 'days') < 0)
            setDisplayedData(newData);
            setStatus('All');
            setSearchInput('')
        }
        else{
            return setDisplayedData(data);
        }
    }

    const newData = paginate(displayedData, pageNumber, 10);

    const EmptyIcon = <img src={Empty} alt='Empty list' style={{ width: '100px'}}/>

   return (
       <div className='payment-history-container'>
            <div className='inner-container'>
              <div className='payout-header-container'>
                    <h2>{`Last Payout: ${data[data.length-1] ? data[data.length-1].date : 'Pending'}`}</h2>
                  <CSVLink { ...csvReport}>
                      <Button className='all-button' type='primary' size={"large"}>Export</Button>
                  </CSVLink>
           </div>
              <div className='table-container'>
                  <Table className="table" locale={{emptyText: EmptyIcon }}    columns={payoutColumns} dataSource={payout} size="large"  pagination={false}/>
               </div>
            </div>
           <div className='inner-container payment-history-table'>
               <div className='payment-container'>
               <div className='filterContainer'>
                   <RangePicker className="range-picker" onChange={handleDateChange} size='large'/>
                   <InputNumber
                       defaultValue={1000}
                       formatter={value => `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                       parser={value => value.replace(/\₦\s?|(,*)/g, '')}
                       className='price-range'
                       value={startPrice}
                       onChange={price => setStartPrice(price)}
                   />
                   <SwapRightOutlined className='price-arrow' style={{margin: 'auto 20px'}}/>
                   <InputNumber
                   defaultValue={20000}
                   formatter={value => `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                   parser={value => value.replace(/\₦\s?|(,*)/g, '')}
                   value={endPrice}
                   onChange={price => setEndPrice(price)}
                   className='price-range'
                   on
               />
               </div>
               <Button className='clear-filter-btn all-button'type='primary' size={"large"} onClick={clearFilter}>Clear Filter</Button>
               </div>
           </div>
           <div className='table-container'>
           <Table className="table" locale={{emptyText: EmptyIcon }} columns={paymentHistoryColumns} dataSource={newData} size="large"  pagination={false}/>
           </div>
           <Pagination current={pageNumber} onChange={handlePageChange} total={displayedData.length} style={{alignSelf: 'end'}}/>
    </div>
   )
}

const mapStateToProps = ({user, payouts, bank}) =>({user, payouts, bank });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(PayoutScreen);