import React, { useEffect, useState } from "react";
import { Steps } from "antd";
import UploadLicence from "./UploadLicence";
import "../../styles/main.css";
import VehicleForm from "./VehicleForm";
import VehicleType from "./VehicleType";
import useWindowSize from "../../hooks/useWindowSize";
import { authenticatedUser } from "../../helper/AuthHelper";
import { useHistory } from "react-router-dom";

const { Step } = Steps;

const AddVehicle = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [vehicleType, setVehicleType] = useState("");
  const [data, setData] = useState({
    make: "",
    model: "",
    color: "",
    plate_number: "",
    imageUrl: null,
    registration_number: "",
    engine_number: "",
    chassis_number: "",
    tracking_id: "",
    max_weight: "",
    size: "",
    axle_type: "single",
    vehicle_documents: [
      { doc_type: "road_worthiness", image: { original: "" } },
      { doc_type: "insurance", image: { original: "" } },
      { doc_type: "vehicle_license", image: { original: "" } },
    ],
  });
  const [loadingImage, setLoadingImage] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [s3ImageUrl, sets3ImageUrl] = useState(null);
  const [fileList1, setFileList1] = useState([]);
  const [uploadUrl1, setUploadUrl1] = useState(null);
  const [s3ImageUrl1, sets3ImageUrl1] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageUrl1, setImageUrl1] = useState(null);
  const [imageUrl2, setImageUrl2] = useState(null);

  const history = useHistory();
  const userDetails = authenticatedUser();
  const isEmployeeDriver = userDetails?.user_type == "driver" && userDetails?.is_employee;

  const handleSelectChange = (value) => {
    const newVehicle = { ...data };
    newVehicle.axle_type = value;
    setData(newVehicle);
  };

  const options = [
    { label: "20 Feet", value: 20 },
    { label: "40 Feet", value: 40 },
  ];

  const truckOptions = [
    { label: "Small", value: 50 },
    { label: "Large", value: 60 },
  ];

  const handleChange = ({ target: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const handleStepChange = (next) => {
    setCurrentStep(next);
  };

  const handleFinish = () => {
    return props.history.replace("/app/vehicle-list");
  };

  const size = useWindowSize();

  useEffect(() => {
    if (isEmployeeDriver) {
      history.replace("/app/vehicle-list");
    }
  }, [userDetails]);

  return (
    <div className="add-vehicle">
      <Steps
        className="steps"
        current={currentStep}
        size={"small"}
        direction={size.width < 500 ? "vertical" : "horizontal"}
      >
        <Step title="Step 1" description="Upload Vehicle License." />
        <Step title="Step 2" description="Select Vehicle Type" />
        <Step title="Step 3" description="Add a Vehicle" />
        {/*<Step title="Step 4" description="Add Account" />*/}
      </Steps>
      <div>
        {currentStep === 0 && (
          <UploadLicence
            data={data}
            handleStepChange={handleStepChange}
            imageUrl={imageUrl2}
            setImageUrl={setImageUrl2}
          />
        )}
        {currentStep === 1 && (
          <VehicleType
            handleStepChange={handleStepChange}
            setVehicleType={setVehicleType}
            data={data}
            setData={setData}
            vehicleType={vehicleType}
          />
        )}
        {currentStep === 2 && (
          <VehicleForm
            data={data}
            options={options}
            truckOptions={truckOptions}
            handleChange={handleChange}
            handleStepChange={handleStepChange}
            handleSelectChange={handleSelectChange}
            uploadUrl={uploadUrl}
            fileList={fileList}
            loadingImage={loadingImage}
            imageUrl={imageUrl}
            setUploadUrl={setUploadUrl}
            sets3ImageUrl={sets3ImageUrl}
            setImageUrl={setImageUrl}
            setFileList={setFileList}
            setLoadingImage={setLoadingImage}
            s3ImageUrl={s3ImageUrl}
            vehicleType={vehicleType}
            imageUrl1={imageUrl1}
            setImageUrl1={setImageUrl1}
            s3ImageUrl1={s3ImageUrl1}
            sets3ImageUrl1={sets3ImageUrl1}
            uploadUrl1={uploadUrl1}
            setUploadUrl1={setUploadUrl1}
            fileList1={fileList1}
            setFileList1={setFileList1}
            imageUrl2={imageUrl2}
            handleFinish={handleFinish}
          />
        )}
      </div>
    </div>
  );
};

export default AddVehicle;
