import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from '../constants/ThemeConstant';
// import { env } from './EnvironmentConfig'
// import {
//     PUBNUB_PUBLISH_KEY,
//     PUBNUB_SUBSCRIBE_KEY
// } from '@env';

export const config = {
}

export const APP_NAME = 'Emilus';
export const API_BASE_URL = 'http://dev.admin.gotruck.io/api/v1/';
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

// export const THEME_CONFIG = {
//     navCollapsed: false,
//     sideNavTheme: SIDE_NAV_LIGHT,
//     locale: 'en',
//     navType: NAV_TYPE_SIDE,
//     topNavColor: '#3e82f7',
//     headerNavColor: '',
//     mobileNav: false,
//     currentTheme: 'light'
// };