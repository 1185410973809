import React, { useState, useEffect } from "react";
import Input from "../components/common/Input";
import Empty from "../assets/svgs/empty.svg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxFunctions } from "../helper/ReduxHelper";
import { Avatar, Button, Image, Table, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "../styles/main.css";
import empty from "../assets/empty.jpeg";
import View from "../assets/svgs/view.svg";
import StatusBadge from "../components/common/StatusBadge";
import { authenticatedUser } from "../helper/AuthHelper";

const VehicleList = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [statusChanged, setStatusChanged] = useState(false);
  const [displayedData, setDisplayedData] = useState([]);
  const [TrackerStatuses, setTrackerStatuses] = useState([]);

  const userDetails = authenticatedUser();
  const isEmployeeDriver = userDetails?.user_type == "driver" && userDetails?.is_employee;

  const getVehicleData = () => {
    props
      .getVehicles()
      .then((res) => {
        if (data.length === 0) {
          const newData = [];
          res.payload.data.vehicles.forEach((vehicle) => {
            if (!vehicle.tracking_id || vehicle.tracking_id === "") {
              vehicle.tracker = "N/A";
            } else {
              props
                .getTrackersHealth([vehicle.tracking_id])
                .then((res) => {
                  vehicle.tracker = res?.payload?.data[0]?.status;
                  setStatusChanged((statusChanged) => !statusChanged);
                  // return res?.payload?.data[0]?.status
                })
                .catch((e) => {
                  // vehicle.tracker = "pending"
                });
            }
            newData.push(vehicle);
          });
          setData(newData);
          setData2(newData);
        }
      })
      .catch((error) => {
        // toast.error('Error getting vehicles data!');
        return error;
      });
  };

  useEffect(getVehicleData, []);
  useEffect(() => {
    setData(data2);
  }, [statusChanged]);

  const handleSearchChange = ({ currentTarget: input }) => {
    setSearchInput(input.value);
  };

  useEffect(() => {
    const newData = data.filter((row) => {
      return row.make.toLowerCase().includes(searchInput.toLowerCase());
    });
    searchInput.length === 0 ? setDisplayedData(data) : setDisplayedData(newData);
  }, [searchInput]);

  const EmptyIcon = <img src={Empty} alt="Empty list" style={{ width: "100px" }} />;

  // const _getTrackersHealth = (imei) => {
  //     if(!imei || imei === ""){
  //         return "n/a"
  //     }
  //     props.getTrackersHealth(imei).then(res => {
  //         console.log(res?.payload?.data[0]?.status);
  //         return res?.payload?.data[0]?.status
  //     }).catch(e => {
  //         console.log(e)
  //         return "pending"
  //     })
  // }

  const vehicleListColumns = [
    {
      title: "",
      dataIndex: "image",
      key: "image",
      // responsive: ['md'],
      render: (text) => <Avatar shape="square" src={text ? <Image src={text} /> : <Image src={empty} />} />,
    },
    {
      title: "Make",
      dataIndex: "make",
      key: "make",
      // responsive: ['md'],
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      responsive: ["md"],
    },
    {
      title: "Plate Number",
      dataIndex: "plate_number",
      key: "plate_number",
      responsive: ["md"],
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      // responsive: ['md'],
    },
    {
      title: "Tracker Status",
      dataIndex: "tracker",
      key: "tracker",
      // responsive: ['md'],
      render: (text) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {!text ? <Spin indicator={true} /> : <StatusBadge status={text || "pending"} />}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "_id",
      key: "_id",
      render: (text) => (
        <Link to={`/app/vehicle/${text}`}>
          <div style={{ width: "30px", height: "15px" }}>
            <img style={{ width: "100%", height: "100%" }} src={View} alt="view" />
          </div>
        </Link>
      ),
      // responsive: ['md'],
    },
  ];

  return (
    <div>
      <h2 className="header">Vehicle List</h2>
      <div className="inner-container">
        <div className="driver-search-container">
          <Input
            placeholder="Search make"
            size="large"
            value={searchInput}
            className="past-trip-input"
            prefix={<SearchOutlined />}
            handleChange={handleSearchChange}
          />
          {!isEmployeeDriver && (
            <Button
              size={"large"}
              className="all-button"
              onClick={() => props.history.push("/app/add-vehicle")}
              type="primary"
            >
              Add New vehicle
            </Button>
          )}
        </div>
        <div className="table-container">
          <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  props.history.push(`/app/vehicle/${record._id}`);
                },
              };
            }}
            className="table"
            locale={{ emptyText: EmptyIcon }}
            loading={props.loadingVehicles}
            columns={vehicleListColumns}
            dataSource={searchInput ? displayedData : data}
            size="large"
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, vehicles, loadingVehicles }) => ({ user, vehicles, loadingVehicles });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(VehicleList);
