import React, { useState, useEffect } from 'react';
import { Line} from '@ant-design/charts';
import '../../styles/main.css'
import { Select, Button, Row, Col } from 'antd';
import useWindowSize from "../../hooks/useWindowSize";
import {ArrowUpOutlined, CloudDownloadOutlined} from "@ant-design/icons";
import LineComponent from "./components/LineComponent";



const { Option } = Select;

const DayDistance = () => {

    const size = useWindowSize();

    const data = [
        { date: 'July 1', amount: 20 },
        { date: 'July 2', amount: 50},
        { date: 'July 3', amount: 40},
        { date: 'July 4', amount: 45 },
        { date: 'July 5', amount: 60 },
        { date: 'July 6', amount: 40 },
        { date: 'July 7', amount: 20 },
    ];



    const handleChange = (value) => {
        // console.log(`selected ${value}`);
    }

    const options = [
        {title: 'Today', value: 'today'},
        {title: 'This Week', value: 'this_month'},
        // {title: 'This Year', value: 'this_year'}
    ]

    return(
        <LineComponent data={data} handleChange={handleChange}
                       head={'Fault/Breakdown'}
                       dateData={'8 - 15 Jul, 2020'}
                       amount={'2 times'}
                       growth={'10% growth from yesterday'}
                       options={options}
        />
    )
}


export default DayDistance;