import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Empty from '../assets/svgs/empty.svg'
import { reduxFunctions } from '../helper/ReduxHelper'
import TripItem from "../components/TripItem";
import {getOngoingTrips} from "../redux/actions/Action";
import {Spin} from "antd";
import {toast} from "react-toastify";
import io from 'socket.io-client';


const LiveTrips = (props) => {
    const [data, setData] = useState(props.ongoing_trips);
    const [loading, setLoading] = useState(false);


    const { ongoing_trips } = props;



    const getLiveTripData = async () => {
        setLoading(true);
        await props.getOngoingTrips().then(res => {
            setData(res.payload.data.trips)
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            // toast.error('Error getting live trips!');
            return error
        })
    }

    const refreshTripData = async () => {
        props.getOngoingTrips().then(res => {
            setData(res.payload.data.trips)
        }).catch((error) => {;
            return error
        })
    }

    useEffect(getLiveTripData, []);

    const _getTripToken = (id) => {
        props.getTripToken(id).then(res => {
            // console.log(res);
        }).catch(error => {
            // console.log(error);
        })
    }






    return(
        <div style={{minHeight: '80vh'}}>
            <h2 className='header'>Live Trips</h2>
            {loading ? <div className='center'>
                    <Spin size="large"/>
            </div> :
                data.length > 0 ? data.map(trip => <TripItem data={trip} refreshTripData ={refreshTripData } _getTripToken={_getTripToken}  key={trip._id} live={true}/>) :
                <div style={{background: "white", height: '75vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {/*<h2>No Live trip yet!</h2>*/}
                        <img src={Empty} alt='Empty list' style={{ width: '100px' }}/>
                </div>}
        </div>
    )
}

const mapStateToProps = ({user, ongoing_trips}) =>({user, ongoing_trips});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(LiveTrips);