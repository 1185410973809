import React, { useState, useEffect } from "react";
import { Line } from "@ant-design/charts";
import "../../styles/main.css";
import { Select, Button, Row, Col } from "antd";
import useWindowSize from "../../hooks/useWindowSize";
import { ArrowDownOutlined, ArrowUpOutlined, CloudDownloadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import LineComponent from "./components/LineComponent";
import { reduxFunctions } from "../../helper/ReduxHelper";
import moment from "moment";
import { revenuePercentage, revenuePerDay, revenuePerWeek } from "../../helper/DataHelper";
import { trips } from "../../tests/mockData/schema/trip";
import currency from "currency.js";
import { toast } from "react-toastify";

const { Option } = Select;

const DayRevenue = (props) => {
  const [data, setData] = useState([{ y: 0, x: moment().valueOf() }]);
  const [revenueData, setRevenueData] = useState([]);

  const getPaymentHistory = async () => {
    await props
      .getPaymentHistory()
      .then((res) => {
        setRevenueData(res.payload.data.payments);
        setData(revenuePerDay(res.payload.data.payments));
      })
      .catch((error) => {
        // toast.error('Error getting revenue data!');
        return error;
      });
  };

  useEffect(() => {
    getPaymentHistory();
  }, []);

  const revenue = revenuePercentage(
    data[data.length - 2] ? data[data.length - 2].y : null,
    data[data.length - 1] ? data[data.length - 1].y : null
  );

  return (
    <LineComponent
      data={data}
      head="Revenue per day"
      dateData={
        revenueData[revenueData.length - 1]
          ? moment(revenueData[revenueData.length - 1].start_time).format("MMMM Do YYYY")
          : "Pending"
      }
      amount={
        revenueData[revenueData.length - 1]
          ? currency(revenueData[revenueData.length - 1].price).format({ symbol: "₦" })
          : "Pending"
      }
      growth={revenue.growth}
      growthInfo={revenue.info}
      info={"Total gross income figure based from the date range given above."}
      unit="₦"
      title="revenue"
      status={revenue.loss}
    />
  );
};

const mapStateToProps = ({ user, paymentHistory, payouts }) => ({ user, paymentHistory, payouts });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(DayRevenue);
