import moment from 'moment';

export const paymentHistoryData = [
    {
        date: moment('2021-03-06').format('LL'),
        trips: '7',
        gross: '16,393.92',
        fees: '345.00',
        total: '9893.01',
        status: 'Pending'
    },
    {
        date: moment('2021-09-02').format('LL'),
        trips: '55',
        gross: '16,393.92',
        fees: '539.44',
        total: '5893.01',
        status: 'Paid'
    },
    {
        date: moment('2021-03-010').format('LL'),
        trips: '10',
        gross: '16,393.92',
        fees: '655.50',
        total: '8893.01',
        status: 'Pending'
    },
    {
        date: moment('2021-10-10').format('LL'),
        trips: '75',
        gross: '16,393.92',
        fees: '245.43',
        total: '12893.01',
        status: 'Paid'
    },
    {
        date: moment('2021-9-09').format('LL'),
        trips: '4',
        gross: '16,393.92',
        fees: '111.43',
        total: '11893.01',
        status: 'Pending'
    },
    {
        date: moment('2021-12-01').format('LL'),
        trips: '19',
        gross: '16,393.92',
        fees: '953.43',
        total: '10893.01',
        status: 'Paid'
    },
    {
        date: moment('2021-10-10').format('LL'),
        trips: '7',
        gross: '16,393.92',
        fees: '345.00',
        total: '9893.01',
        status: 'Pending'
    },
    {
        date: moment('2021-09-05').format('LL'),
        trips: '55',
        gross: '16,393.92',
        fees: '539.44',
        total: '18893.01',
        status: 'Paid'
    },
    {
        date: moment('2020-09-10').format('LL'),
        trips: '10',
        gross: '16,393.92',
        fees: '655.50',
        total: '10893.01',
        status: 'Pending'
    },
    {
        date: moment('2019-5-10').format('LL'),
        trips: '75',
        gross: '16,393.92',
        fees: '245.43',
        total: '12893.01',
        status: 'Paid'
    },
    {
        date: moment('2021-03-19').format('LL'),
        trips: '4',
        gross: '16,393.92',
        fees: '111.43',
        total: '11293.01',
        status: 'Pending'
    },
    {
        date: moment('2021-4-11').format('LL'),
        trips: '19',
        gross: '16,393.92',
        fees: '953.43',
        total: '10893.01',
        status: 'Paid'
    },
    {
        date: moment('2018-09-06').format('LL'),
        trips: '7',
        gross: '16,393.92',
        fees: '345.00',
        total: '12833.01',
        status: 'Pending'
    },
    {
        date: moment('2009-05-03').format('LL'),
        trips: '55',
        gross: '16,393.92',
        fees: '539.44',
        total: '13855.01',
        status: 'Paid'
    },
    {
        date: moment('2010-05-09').format('LL'),
        trips: '10',
        gross: '16,393.92',
        fees: '655.50',
        total: '8853.01',
        status: 'Pending'
    },
    {
        date: moment('2021-5-03').format('LL'),
        trips: '75',
        gross: '16,393.92',
        fees: '245.43',
        total: '13423.01',
        status: 'Paid'
    },
    {
        date: moment('2021-02-4').format('LL'),
        trips: '4',
        gross: '16,393.92',
        fees: '111.43',
        total: '10893.01',
        status: 'Pending'
    },
    {
        date: moment('2011-12-11').format('LL'),
        trips: '19',
        gross: '16,393.92',
        fees: '953.43',
        total: '11893.01',
        status: 'Paid'
    }
];