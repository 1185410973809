import React, { useState } from "react";
import { Button, DatePicker, Form, Input, Spin, Upload } from "antd";
import { connect } from "react-redux";
import loadingIcon from "../components/common/loadingIcon";
import { toast } from "react-toastify";
import { reduxFunctions } from "../helper/ReduxHelper";
import { authenticatedCompany } from "../helper/AuthHelper";

const { Dragger } = Upload;

const SignupDriver = (props) => {
  const [data, setData] = useState({ family_name: "", given_name: "", phone_number: "", email: "", password: "" });
  const [licenseData, setLicenseData] = useState({ license_number: "", license_image: "", expiry_date: "" });
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(authenticatedCompany());
  const [uploadUrl, setUploadUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [s3ImageUrl, setS3ImageUrl] = useState(null);
  const [fileList, setFileList] = useState([]);

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const beforeUpload = async (file) => {
    const fileType = file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf";
    if (!fileType) {
      toast.error("You can only upload an acceptable file");
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toast.error("Image should not exceed 2MB");
      return;
    }
    await props
      .getUploadUrl(file.type)
      .then(async (res) => {
        let url = res.payload.data.upload.url;
        let path = res.payload.data.upload.path;
        setUploadUrl(url);
        setS3ImageUrl(path);
        return fileType && isLt2M;
      })
      .catch((e) => {
        toast.error("Image should not exceed 2MB");
        return;
      });
  };

  const handleFileChange = (info) => {
    setImageUrl(null);
    setFileList([]);
    // data.profile_picture = null;
    let file = info.file;
    if (file.status === "removed") {
      setImageUrl(null);
      setFileList([]);
    } else if (file.status === "uploading") {
      setFileList([file]);
      // setLoadingImage(true);
    } else if (file.status === "done") {
      let url = s3ImageUrl;
      setFileList([{ uid: "current", name: file.name, status: "done", url: url }]);
      setImageUrl(url);
      // setLoadingImage(false);
    }
  };

  const handleUpload = async ({ file, onSuccess, onError, onProgress }) => {
    const xhr = new XMLHttpRequest();
    // S3 requires PUT method!
    xhr.open("PUT", uploadUrl);
    xhr.onreadystatechange = async () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          // Calls the update prop
          setLicenseData({ ...licenseData, license_image: s3ImageUrl });
          setImageUrl(s3ImageUrl);
          onSuccess(null, file);
        } else {
          toast.error("Upload Failed");
        }
      }
    };
    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        onProgress({ percent: Math.round((e.loaded / e.total) * 100).toFixed(2) }, file);
      }
    };
    xhr.send(file);
  };

  const draggerProps = {
    name: "file",
    beforeUpload: beforeUpload,
    multiple: true,
    action: uploadUrl,
    onChange: handleFileChange,
    customRequest: (options) => handleUpload(options),
    // fileList: {fileList}
  };

  const handleSubmit = async () => {
    setLoading(true);
    await props
      .signUpUserForCompany({ ...data, drivers_license: { ...licenseData } }, company._id)
      .then(async (res) => {
        toast.success("Driver created successfully");
        setLoading(false);
        props.history.push("/app/driver-list");
      })
      .catch((error) => {
        toast.error(error.error?.response?.data?.message);
        setLoading(false);
        return error;
      });
  };

  return (
    <div>
      <h2 className="header">Create Driver Account</h2>
      <div className="inner-container">
        <div className={"signup-driver-inner"}>
          <Form layout="vertical" name="login-form" onFinish={handleSubmit}>
            <Form.Item label="First name" className="label">
              <Input
                className="input"
                name="given_name"
                value={data.given_name}
                onChange={handleChange}
                size={"large"}
              />
            </Form.Item>
            <Form.Item label="Last name" className="label">
              <Input
                className="input"
                name="family_name"
                value={data.family_name}
                onChange={handleChange}
                size={"large"}
              />
            </Form.Item>

            <Form.Item label="Phone Number" className="label">
              <Input
                className="input"
                name="phone_number"
                value={data.phone_number}
                onChange={handleChange}
                size={"large"}
              />
            </Form.Item>

            <Form.Item
              label="Email"
              rules={[
                {
                  message: "Please input your email",
                },
                {
                  type: "email",
                  message: "Please enter a valid email!",
                },
              ]}
              className="label"
            >
              <Input className="input" name="email" value={data.email} onChange={handleChange} size={"large"} />
            </Form.Item>

            <Form.Item label="License Number" className="label">
              <Input
                className="input"
                name="license_number"
                value={licenseData.license_number}
                onChange={(e) => setLicenseData({ ...licenseData, license_number: e.target.value })}
              />
            </Form.Item>

            <Form.Item label="Upload License ID" className="label">
              <Dragger {...draggerProps}>
                <p style={{ fontWeight: "normal" }}>
                  <span style={{ color: "#e27626", fontSize: "14px" }}>Click or upload</span> or drag and drop
                </p>
                <p className="ant-upload-hint" style={{ fontSize: "12px", fontWeight: "normal" }}>
                  PNG, JPG or PDF(max 2MB)
                </p>
              </Dragger>
            </Form.Item>

            <Form.Item label="License Expiry Date" className="label">
              <DatePicker
                picker="date"
                className="input"
                value={licenseData.expiry_date}
                style={{ width: "100%" }}
                onChange={(date) => setLicenseData({ ...licenseData, expiry_date: date })}
              />
            </Form.Item>

            <Form.Item label="Password" className="label">
              <Input.Password
                className="input"
                name="password"
                value={data.password}
                onChange={handleChange}
                size={"large"}
              />
            </Form.Item>

            {/* <p className="p2">
              Creating an account means you agree with our <a>Terms of Service</a>, <a>Privacy Policy</a> and our
              default Notification Settings
            </p> */}

            <Form.Item>
              <Button type="primary" htmlType="submit" block className="all-button" size={"large"}>
                {loading ? <Spin indicator={loadingIcon} /> : <span>Create account</span>}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, company }) => ({ user, company });
const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(SignupDriver);
