import React, { useEffect, useState } from "react";
import { Button, Spin } from "antd";
import "../../styles/main.css";
import { connect } from "react-redux";
import { reduxFunctions } from "../../helper/ReduxHelper";
import AddBankModal from "../../components/common/AddBankModal";
import { toast } from "react-toastify";
import { BANKS } from "../../configs/common-options";
import BillingCard from "../../components/billing/BillingCard";
import axios from "axios";

const Billing = (props) => {
  const [data, setData] = useState(props.account_list);
  const [verifying, setVerifying] = useState(false);
  const [defaultBank, setDefaultBank] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingBanks, setLoadingBanks] = useState(false);
  const [accountInfo, setAccountInfo] = useState({
    account_number: "",
    bank_name: "",
    bank_code: "",
    account_name: "",
  });
  const [radioChanged, setRadioChanged] = useState(false);

  const { account_number, bank_code } = accountInfo;

  const handleInfoChange = ({ target: input }) => {
    const newData = { ...accountInfo };
    newData[input.name] = input.value;
    setAccountInfo(newData);
  };

  const handleSelectChange = (value) => {
    BANKS.forEach((bank) => {
      if (bank.value === value) {
        const newAccountInfo = { ...accountInfo };
        newAccountInfo.bank_name = value;
        newAccountInfo.bank_code = bank.code;
        setAccountInfo(newAccountInfo);
      }
    });
  };

  const _startLoading = () => {
    setLoading(true);
  };

  const _stopLoading = () => {
    setLoading(false);
  };

  const onDelete = async (id, e) => {
    e.preventDefault();

    await props
      .deleteBank(id)
      .then((res) => {
        // window.location.href = "/app/settings/billing";
        setRadioChanged((radioChanged) => !radioChanged);
      })
      .catch((error) => {
        toast.error("Error deleting bank!");
        return error;
      });
  };

  const handleRadio = async (bank) => {
    if (bank.account_number !== defaultBank) {
      await props
        .setActiveBank(bank._id)
        .then((res) => {
          setRadioChanged((radioChanged) => !radioChanged);
        })
        .catch((error) => {
          toast.error("Error");
          return error;
        });
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const clearAccountInfo = () => {
    setAccountInfo({ account_number: "", bank_name: "", bank_code: "" });
  };

  const getAccountname = async () => {
    if (account_number.length === 10 && bank_code) {
      setVerifying(true);
      await axios
        .get(`https://api.paystack.co/bank/resolve?account_number=${account_number}&bank_code=${bank_code}`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_KEY}`,
          },
        })
        .then(async (res) => {
          setAccountInfo({
            ...accountInfo,
            account_name: res.data.data.account_name,
          });
          setVerifying(false);
        })
        .catch((error) => {
          setVerifying(false);
          toast.error("Error validating bank");
          return error;
        });
    }
  };

  useEffect(() => {
    getAccountname();
  }, [account_number]);

  const handleAdd = async () => {
    _startLoading();
    await props
      .createBank(accountInfo)
      .then(async (res) => {
        toast.success("Bank created!");
        _stopLoading();
        clearAccountInfo();
        // window.location.href = "/app/settings/billing";
        setRadioChanged((radioChanged) => !radioChanged);
      })
      .catch((error) => {
        _stopLoading();
        clearAccountInfo();
        toast.error("Error adding bank!");
        return error;
      });
    setIsModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    clearAccountInfo();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    clearAccountInfo();
  };

  const getBanks = async () => {
    setLoadingBanks(true);
    await props
      .getAccounts()
      .then((res) => {
        const newData = [...res.payload.data.banks];
        const displayData = [];
        for (const bankData of newData) {
          for (const bankInfo of BANKS) {
            if (bankData.bank_name === bankInfo.value) {
              bankData.image = bankInfo.image;
            }
          }
          displayData.push(bankData);
        }
        setData(displayData);
        setLoadingBanks(false);
      })
      .catch((error) => {
        setLoadingBanks(false);
        toast.error("Error getting getting banks data!");
        return error;
      });
  };

  useEffect(getBanks, [radioChanged]);

  const activeBank = data?.find((a) => a.active);
  const notActiveBanks = data?.filter((a) => !a.active);

  const sortedBanks = activeBank ? [activeBank, ...notActiveBanks] : [...notActiveBanks];

  return (
    <>
      <div className="billing">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #E4E7EC",
            paddingBottom: "5px",
            marginBottom: "20px",
          }}
        >
          <div>
            <h2 className="text-7xl text-primary">Billing</h2>
            <p style={{ color: "#667085", margin: 0 }}>You will receive payments in your Default account</p>
          </div>
          <Button className="billing-button all-button" type="primary" onClick={showModal}>
            Add new bank
          </Button>
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          {loadingBanks ? (
            <div className="billing-center">
              <Spin size="default" />
            </div>
          ) : (
            sortedBanks?.map((detail, i) => (
              <BillingCard
                detail={detail}
                key={i}
                defaultBank={defaultBank}
                setDefaultBank={setDefaultBank}
                onDelete={onDelete}
                handleRadio={handleRadio}
              />
            ))
          )}
        </div>
        <AddBankModal
          verifying={verifying}
          isModalVisible={isModalVisible}
          handleAdd={handleAdd}
          handleCancel={handleCancel}
          handleOk={handleOk}
          loading={loading}
          accountInfo={accountInfo}
          handleInfoChange={handleInfoChange}
          handleSelectChange={handleSelectChange}
        />
      </div>
    </>
  );
};

const mapStateToProps = ({ account_list, account_name }) => ({ account_list, account_name });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
