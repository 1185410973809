import React, { useState, useEffect } from "react";
import DashboardCard from "./DashboardCard";
import { connect } from "react-redux";
import { reduxFunctions } from "../../helper/ReduxHelper";
import moment from "moment";
import { revenuePerDay, tripPerWeek } from "../../helper/DataHelper";
import { toast } from "react-toastify";

const WeeklyTrips = (props) => {
  const [data, setData] = useState([{ y: 0, x: moment().valueOf() }]);

  const getPaymentHistory = async () => {
    await props
      .getPaymentHistory()
      .then((res) => {
        setData(tripPerWeek(res.payload.data.payments));
      })
      .catch((error) => {
        // toast.error('Error getting trips data!');
        return error;
      });
  };

  useEffect(() => {
    getPaymentHistory();
  }, []);

  return (
    <DashboardCard
      data={data}
      head="Total weekly trips"
      number={data[data.length - 1] ? data[data.length - 1].y : "0"}
      info="Total weekly trips"
      title="trips"
      loading={props.loadingPayouts}
    />
  );
};

const mapStateToProps = ({ user, payouts, loadingPayouts }) => ({ user, payouts, loadingPayouts });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(WeeklyTrips);
