import React from 'react';
import { AutoComplete } from 'antd';
import {CompassOutlined, PieChartOutlined} from "@ant-design/icons";
const optionsDriver = [
    {
        value: 'Profile',
        display: '/app/profile'
    },
    {
        value: 'Past Trips',
        display: '/app/trips/past-trips'
    },
    {
        value: 'Vehicles',
        display: '/app/vehicle-list'
    },
    {
        value: 'Live Trips',
        display: '/app/trips/live-trips'
    },
    {
        value: 'Finance',
        display: '/app/finance/payouts'
    },
    {
        value: 'Settings',
        display: '/app/settings'
    },
];

const optionsCompany = [
    {
        value: 'Profile',
        display: '/app/profile'
    },
    {
        value: 'Company Profile',
        display: '/app/profile-company'
    },
    {
        value: 'Past Trips',
        display: '/app/past-trips'
    },
    {
        value: 'Vehicles',
        display: '/app/vehicle-list'
    },
    {
        value: 'Schedule',
        display: '/app/schedule/live-trips'
    },
    {
        value: 'Finance',
        display: '/app/finance/payouts'
    },
    {
        value: 'Settings',
        display: '/app/settings'
    },
];


const Complete = (props) => {
    const onSelect = (value, record) => {
        props.history.push(record.display);
    };

    const options = props.userType === 'company' ? optionsCompany : optionsDriver

    return (
        <AutoComplete
            style={{
                width: 200,
            }}
            onSelect={onSelect}
            options={options}
            placeholder="Search..."
            filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
        />
    )
};

export default Complete