import { PAYOUT_STATES } from '../constants/Enums'


export const formatPayoutState = (state) => {
    switch(state){
        case PAYOUT_STATES.PAID:
            return 'Paid';
            break;
        case PAYOUT_STATES.FAILED:
            return 'Failed';
            break;
        case PAYOUT_STATES.REVERSED:
            return 'Reversed';
            break;
        case PAYOUT_STATES.IN_PROGRESS:
            return 'In Progress'
            break;
        default:
            return 'In Progress';
    }
}