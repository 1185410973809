import React, { useEffect, useState } from "react";
import Icon, { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Button, InputNumber, Modal, Spin, Table } from "antd";
import EmptyState from "../components/wallet/EmptyState";
import { formatMoney } from "../helper/UtilityHelper";
import moment from "moment";
import { toast } from "react-toastify";
import { usePaystackPayment } from "react-paystack";
import { reduxFunctions } from "../helper/ReduxHelper";
import { connect } from "react-redux";
import loadingIcon from "../components/common/loadingIcon";
import PaymentProcessing from "../components/wallet/PaymentProcessing";
import { authenticatedCompany, authenticatedUser } from "../helper/AuthHelper";

const Wallet = (props) => {
  const [balVisible, setBalVisible] = useState(true);
  const [fundAmount, setFundAmount] = useState("");
  const [processingModalOpen, setProcessingModalOpen] = useState(false);

  const company = authenticatedCompany();

  const walletBalance = company?.wallet_id?.balance || 0;

  const config = {
    reference: new Date().getTime().toString(),
    email: company?.email,
    amount: fundAmount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
  };

  const initializePayment = usePaystackPayment(config);

  const handleStatus = (status, record) => {
    if (record.refunded) return { backgroundColor: "#F2F4F7", color: "#344054" };
    if (status == "CREDIT") return { backgroundColor: "#ECFDF3", color: "#027A48" };
    if (status == "DEBIT") return { backgroundColor: "#FEF3F2", color: "#B42318" };
  };

  const handleBalance = () => {
    if (balVisible) {
      localStorage.setItem("balVisible", JSON.stringify("hide"));
      setBalVisible(false);
    } else {
      localStorage.setItem("balVisible", JSON.stringify("show"));
      setBalVisible(true);
    }
  };

  //RECENT TRANSACTIONS
  const getRecentTransactions = async () => {
    try {
      const res = await props.getRecentTransactions(company?.wallet_id?._id, 10);
      if (res?.error) {
        throw new Error(res?.error);
      }
      return;
    } catch (error) {
      toast.error("An error occured while getting your transactions");
      return error;
    }
  };

  const fundWalletSuccessfull = () => {
    getRecentTransactions();
    setTimeout(() => {
      setProcessingModalOpen(false);
      setFundAmount("");
    }, 3000);
  };

  const fundWallet = async (data) => {
    setProcessingModalOpen(true);
    const payload = { reference: data?.reference };
    try {
      const res = await props.fundWallet(company?._id, payload);
      if (res?.error) {
        throw new Error(res?.error);
      }

      fundWalletSuccessfull();
      return;
    } catch (error) {
      setProcessingModalOpen(false);
      toast.error("An error occured, please try again");
      return error;
    }
  };

  const onPaymentSuccess = (reference) => {
    fundWallet(reference);
  };

  const onPaymentClose = () => {};

  const handleCloseModal = () => {
    setProcessingModalOpen(false);
  };

  const handleFundWallet = () => {
    if (!fundAmount) return toast.error("Please enter a valid amount");
    if (+fundAmount < 5000) return toast.error("Minimum topup amount is ₦5000");

    initializePayment(onPaymentSuccess, onPaymentClose);
  };

  useEffect(() => {
    if (localStorage.getItem("balVisible")) {
      if (JSON.parse(localStorage.getItem("balVisible")) == "hide") {
        setBalVisible(false);
      }
    }
  }, []);
  useEffect(() => {
    return () =>
      clearTimeout(
        setTimeout(() => {
          setProcessingModalOpen(false);
          setFundAmount("");
        }, 3000)
      );
  }, []);

  useEffect(() => {
    if (company?.wallet_id) {
      getRecentTransactions();
    }
  }, []);

  const transactionColumns = [
    {
      title: "Transaction ID",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "Date",
      dataIndex: "transaction_date",
      key: "transaction_date",
      render: (text, record) => <p>{moment(text).format("MMM Do, YYYY")}</p>,
    },
    {
      title: "Status",
      dataIndex: "transaction_type",
      key: "transaction_type",

      render: (text, record) => (
        <p
          style={{
            ...handleStatus(text, record),
            padding: "6px 12px",
            borderRadius: "10px",
            display: "inline",
            fontWeight: "500",
            textTransform: "lowercase",
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Amount (₦)",
      dataIndex: "amount",
      key: "amount",

      render: (text, record) => <p>{formatMoney({ value: text })}</p>,
    },
  ];

  return (
    <>
      <Container>
        <h2 className="header">Wallet</h2>
        <div className="balance-container">
          <BalanceCard>
            <div>
              <h3>
                Current Balance
                <span className="eye-icon" onClick={handleBalance}>
                  <Icon component={balVisible ? EyeInvisibleOutlined : EyeOutlined} />
                </span>
              </h3>
              <p>{balVisible ? formatMoney({ value: walletBalance, money: true }) : "********"}</p>
            </div>
          </BalanceCard>

          <FundWalletCard>
            <div style={{ width: "100%" }}>
              <h3>Enter top-up amount</h3>
              <div>
                <div className="input-container">
                  <div className="currency-container">
                    <img src="/9ja-flag.png" alt="currency" />
                    <p>NGN</p>
                  </div>

                  <InputNumber
                    name="fundAmount"
                    value={fundAmount}
                    disabled={props.fund_wallet_loading}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                    onChange={(value) => setFundAmount(value)}
                    placeholder="Minimum of ₦5000"
                    controls={false}
                    style={{ width: "100%", padding: "6px", borderRadius: "5px", height: "auto" }}
                  />
                  <Button type={"primary"} onClick={props.fund_wallet_loading ? null : handleFundWallet}>
                    {props.fund_wallet_loading ? <Spin indicator={loadingIcon} /> : "Fund Wallet"}
                  </Button>
                </div>
              </div>
            </div>
          </FundWalletCard>
        </div>

        <div className="table-container">
          {props.recent_transactions?.length == 0 ? (
            <EmptyState />
          ) : (
            <>
              <h3>Recent Transactions</h3>
              <div className="">
                <Table
                  className="table"
                  columns={transactionColumns}
                  dataSource={props.recent_transactions}
                  loading={props.recent_transactions_loading}
                  pagination={false}
                />
              </div>
            </>
          )}
        </div>
      </Container>

      <Modal
        visible={processingModalOpen}
        maskClosable={false}
        width={500}
        centered
        onCancel={handleCloseModal}
        footer={""}
      >
        <PaymentProcessing fundAmount={fundAmount} />
      </Modal>
    </>
  );
};

const Container = styled.div`
  .balance-container {
    margin: 16px 0px 24px 0px;
    display: flex;
    gap: 24px;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }
  .table-container {
    padding: 24px;
    background: white;
    border-radius: 5px;
    min-height: 500px;

    h3 {
      font-size: 20px;
      font-weight: semibold;
      margin: 0 0 20px 0;
    }
  }
`;

const BalanceCard = styled.div`
  width: 30%;
  min-height: 150px;
  background-color: #192d46;
  background-image: url("/wallet-bg.png");
  background-repeat: no-repeat;
  background-position: right top;
  border-radius: 5px;
  padding: 16px 24px;
  height: auto;
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }

  h3 {
    color: #d1d5da;
    font-size: 20px;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  p {
    color: white;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
  .eye-icon {
    cursor: pointer;
  }
`;

const FundWalletCard = styled.div`
  width: 70%;
  background: white;
  border-radius: 5px;
  padding: 16px 24px;
  height: auto;
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }

  h3 {
    font-size: 24px;
    font-weight: semibold;
    margin: 0;
  }
  .input-container {
    width: 100%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .currency-container {
    background: #edf5ff;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  button {
    width: 150px;
    height: 42px;
    border-radius: 5px;
  }
`;

const mapStateToProps = ({
  fundWallet,
  fund_wallet_loading,
  recent_transactions_loading,
  recent_transactions,
  getRecentTransactions,
}) => ({
  fundWallet,
  fund_wallet_loading,
  recent_transactions_loading,
  recent_transactions,
  getRecentTransactions,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
