import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ScaleLoader from "react-spinners/ScaleLoader";
import styled from "styled-components";
import Modal from "react-awesome-modal";
import { SearchOutlined } from "@ant-design/icons";
import { AiOutlineClose } from "react-icons/ai";
import { Input, Divider } from "antd";
import { reduxFunctions } from "../../helper/ReduxHelper";

const TerminalList = ({
  visible,
  closeModal,
  fetchTerminals,
  loading_fetch_terminals,
  data,
  setData,
  terminalOpener,
}) => {
  const [searchText, setSearchText] = useState("");
  const [terminals, setTerminals] = useState([]);
  const [filteredTerminals, setFilteredTerminals] = useState([]);

  useEffect(() => {
    const getTerminals = async () => {
      try {
        const res = await fetchTerminals();
        //console.log(res?.payload?.data?.terminals);
        setTerminals(res?.payload?.data?.terminals);
        if (res?.error) {
          throw new Error(res?.error);
        }
      } catch (error) {
        // console.log(error, "error");
        return error;
      }
    };
    getTerminals();
  }, []);

  useEffect(() => {
    const filteredTerminals = terminals.filter((term) => {
      return (
        term.name.toLowerCase().includes(searchText.toLowerCase()) ||
        term?.location?.address.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setFilteredTerminals(filteredTerminals);
  }, [searchText]);

  const terminalData = searchText.length > 0 ? filteredTerminals : terminals;

  const handleSelect = (address, coords) => {
    terminalOpener == "start"
      ? setData({ ...data, startAddress: address, startCoordinates: coords })
      : setData({ ...data, endAddress: address, endCoordinates: coords });
    setSearchText("");
    closeModal();
  };

  return (
    <Modal visible={visible} width="650" height="700" effect="fadeInUp" onClickAway={() => {}}>
      <Inner>
        <Head id={"icon"}>
          <AiOutlineClose size={21} onClick={closeModal} className={"icon"} />
        </Head>
        <Input
          className={"input"}
          size="large"
          placeholder="Search for terminal"
          prefix={<SearchOutlined />}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        {!loading_fetch_terminals ? (
          terminalData?.length > 0 ? (
            <div>
              {terminalData.map((terminal) => {
                return (
                  <Item
                    key={terminal._id}
                    onClick={() =>
                      handleSelect(terminal?.location?.address, {
                        lat: terminal?.location?.coordinates[0],
                        lng: terminal?.location?.coordinates[1],
                      })
                    }
                  >
                    <p className={"p1"}>{terminal.name}</p>
                    <p>{terminal?.location?.address}</p>
                  </Item>
                );
              })}
            </div>
          ) : (
            <Center>
              <h3>There are currently no terminal available.</h3>
            </Center>
          )
        ) : (
          <Center>
            <ScaleLoader
              color={"#e27626"}
              loading={loading_fetch_terminals}
              size={150}
              width={8}
              margin={5}
              height={30}
            />
          </Center>
        )}
      </Inner>
    </Modal>
  );
};

const Inner = styled.div`
  margin: 0 auto 10px;
  overflow: scroll;
  height: 100%;
  padding: 10px 30px 30px;

  .input {
    border-radius: 5px;
    margin: 20px 0;
  }
`;

const Head = styled.div`
  display: flex;
  justify-content: flex-end;
  //padding-top: 30px;

  .icon {
    cursor: pointer;
  }
`;

const Item = styled.div`
  background: #fafafa;
  padding: 10px 5px;
  margin-bottom: 5px;
  cursor: pointer;

  p {
    margin: 0;
    font-weight: 300;
  }

  .p1 {
    font-weight: 500;
    //margin-bottom: -5px;
  }

  &:hover {
    background: #f5f4f4;
  }
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
`;

const mapStateToProps = ({ user, loading_fetch_terminals, fetchTerminals }) => ({
  user,
  loading_fetch_terminals,
  fetchTerminals,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(TerminalList);
