import React, { useEffect, useState } from "react";
import { Upload, message, Button } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { reduxFunctions } from "../../helper/ReduxHelper";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "../../styles/main.css";

const { Dragger } = Upload;

const UploadLicence = ({ data, handleStepChange, getUploadUrl, imageUrl, setImageUrl }) => {
  const [fileList, setFileList] = useState([]);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [s3ImageUrl, sets3ImageUrl] = useState(null);

  const navigate = useHistory();

  const beforeUpload = async (file) => {
    const fileType = file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf";
    if (!fileType) {
      message.error("You can only upload an acceptable file");
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return;
    }
    await getUploadUrl(file.type)
      .then(async (res) => {
        let url = res.payload.data.upload.url;
        let path = res.payload.data.upload.path;
        setUploadUrl(url);
        sets3ImageUrl(path);
        return fileType && isLt2M;
      })
      .catch((e) => {
        message.error("Image must smaller than 2MB");
        return;
      });
  };

  const handleAvatarChange = (info) => {
    setImageUrl(null);
    setFileList([]);
    data.profile_picture = null;
    let file = info.file;
    if (file.status === "removed") {
      setImageUrl(null);
      setFileList([]);
    } else if (file.status === "uploading") {
      setFileList([file]);
      setLoadingImage(true);
    } else if (file.status === "done") {
      let url = s3ImageUrl;
      setFileList([
        {
          uid: "current",
          name: file.name,
          status: "done",
          url: url,
        },
      ]);
      setImageUrl(url);
      setLoadingImage(false);
    }
  };

  const handleUpload = async ({ file, onSuccess, onError, onProgress }) => {
    const xhr = new XMLHttpRequest();
    // S3 requires PUT method!
    xhr.open("PUT", uploadUrl);
    xhr.onreadystatechange = async () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          // Calls the update prop
          setImageUrl(s3ImageUrl);
          onSuccess(null, file);
        } else {
          message.error("Upload Failed");
        }
      }
    };
    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        onProgress({ percent: Math.round((e.loaded / e.total) * 100).toFixed(2) }, file);
      }
    };
    xhr.send(file);
  };

  const props = {
    name: "file",
    beforeUpload: beforeUpload,
    multiple: true,
    action: uploadUrl,
    onChange: handleAvatarChange,
    customRequest: (options) => handleUpload(options),
    // fileList: {fileList}
  };

  const handleNext = () => {
    if (s3ImageUrl == null) return toast.error("Please upload vehicle license");

    handleStepChange(1);
  };

  return (
    <div className="upload-licence">
      <h2>Upload Vehicle License</h2>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-text" style={{ fontSize: "12px", color: "#858585" }}>
          (jpeg, png or pdf not more than 2MB)
        </p>
      </Dragger>

      <div className="buttons">
        <Button className="alt-button" size={"large"} onClick={() => navigate.replace("/app/vehicle-list")}>
          Cancel
        </Button>

        <Button size={"large"} className="all-button" type="primary" onClick={handleNext}>
          Next
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, imageToken }) => ({ user, imageToken });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(UploadLicence);
