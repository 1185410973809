import React, { useEffect, useState } from "react";
import moment from "moment";
import { Select, Button, Row, Col, Spin } from "antd";
import { ArrowUpOutlined, CloudDownloadOutlined, ArrowDownOutlined, InfoCircleOutlined } from "@ant-design/icons";
import CustomHistogram from "../../components/common/Histogram";
import useWindowSize from "../../hooks/useWindowSize";
import "../../styles/main.css";
import CustomColumn from "../../components/common/CustomColumn";
import { reduxFunctions } from "../../helper/ReduxHelper";
import { connect } from "react-redux";
import { revenuePerMonth, revenuePerWeek, revenuePerYear, revenuePercentage } from "../../helper/DataHelper";
import { CSVLink, CSVDownload } from "react-csv";
import currency from "currency.js";
import symlog from "d3-scale/src/symlog";
import { toast } from "react-toastify";
import LoadingState from "../../components/util-components/LoadingState";

const { Option } = Select;

const TotalRevenue = (props) => {
  const [wData, setWData] = useState([]);
  const [mData, setMData] = useState([]);
  const [yData, setYData] = useState([]);
  const [selectValue, setSelectValue] = useState("this_week");

  const getPaymentHistory = async () => {
    await props
      .getPaymentHistory()
      .then((res) => {
        setWData(revenuePerWeek(res.payload.data.payments));
        setMData(revenuePerMonth(res.payload.data.payments));
        setYData(revenuePerYear(res.payload.data.payments));
      })
      .catch((error) => {
        // toast.error('Error getting total revenue data!');
        return error;
      });
  };
  useEffect(() => {
    getPaymentHistory();
  }, []);

  const options = [
    { title: "This Week", value: "this_week" },
    { title: "This Month", value: "this_month" },
    { title: "This Year", value: "this_year" },
  ];

  const switchData = (value) => {
    switch (value) {
      case "this_week":
        return wData;
        break;
      case "this_month":
        return mData;
        break;
      case "this_year":
        return yData;
        break;
      default:
        return wData;
    }
  };

  const handleChange = (value) => {
    setSelectValue(value);
  };
  const data = switchData(selectValue);
  const formattedData = data.map((item) => {
    return {
      date: moment(item.date).format("DD/MM/YYYY"),
      amount: currency(item.amount).format({ symbol: "₦" }),
    };
  });

  const headers = [
    { label: "Date", key: "date" },
    { label: "Amount", key: "amount" },
  ];

  const csvReport = {
    filename: "Revenue.csv",
    headers: headers,
    data: formattedData,
  };

  const header = data[data.length - 1] ? currency(data[data.length - 1].amount).format({ symbol: "₦" }) : "Pending";

  const displayDate = data[data.length - 1] ? data[data.length - 1].date : "Pending";

  const revenue = revenuePercentage(
    data[data.length - 2] ? data[data.length - 2].amount : null,
    data[data.length - 1] ? data[data.length - 1].amount : null
  );

  const size = useWindowSize();

  return (
    <Row className={props.margin ? "total-revenue" : " total-revenue revenue-margin"}>
      {props.loadingPayment ? (
        <LoadingState />
      ) : (
        <>
          <Col span={size.width > 800 ? 6 : 24} className="c1">
            <h2 className="header">Total Revenue</h2>
            <p className="date">{displayDate}</p>
            <div className="total-revenue-content">
              <h2 className="header">{header}</h2>
              <p className={`p1 ${revenue.loss ? "loss" : "growth"} `}>
                {" "}
                {revenue.growth} {revenue.info}
              </p>
              <p className="p2">Total gross income figure based from the date range given above.</p>
            </div>
          </Col>
          <Col span={size.width > 800 ? 18 : 24}>
            <div className="report-container">
              <div className="s1 border-radius">
                <Select bordered={false} defaultValue="this_week" onChange={handleChange} size={"large"}>
                  {options.map((opt) => (
                    <Option value={opt.value} key={opt.value} disabled={opt.disabled}>
                      {opt.title}
                    </Option>
                  ))}
                </Select>
              </div>
              <CSVLink {...csvReport}>
                <Button icon={<CloudDownloadOutlined />} size={"large"} className="border-radius">
                  <span>Download Report</span>
                </Button>
              </CSVLink>
            </div>
            <CustomColumn data={data} />
          </Col>
        </>
      )}
    </Row>
  );
};

const mapStateToProps = ({ user, payouts, paymentHistory, loadingPayment }) => ({
  user,
  payouts,
  paymentHistory,
  loadingPayment,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(TotalRevenue);
