import moment from 'moment'

export const payoutData = [{
    payout_period: 'Jan 12 - 19, 2021',
    trips: '4',
    distance: '350 KM',
    gross: '₦16, 393.42',
    fees: '-₦506.22',
    total: '₦15,928.00',
    bank_account: 'Standard Chatered Plc ******17 (NGN)'
}]

export const paymentHistoryArray = [{
    id: '007',
    details: 'From 2 Alexander Road, Ikoyi to 28 Marina Road, Lekki Lagos',
    distance: '30 KM',
    amount: '₦30,500.00',
    date: moment('2021-05-10').format('LL')
},
    {
        id: '006',
        details: 'From 2 Alexander Road, Ikoyi to 28 Marina Road, Lekki Lagos',
        distance: '30 KM',
        amount: '₦30,500.00',
        date: moment('2021-05-10').format('LL')
    },
    {
        id: '005',
        details: 'From 2 Alexander Road, Ikoyi to 28 Marina Road, Lekki Lagos',
        distance: '30 KM',
        amount: '₦30,500.00',
        date: moment('2021-05-10').format('LL')
    },
    {
        id: '004',
        details: 'From 2 Alexander Road, Ikoyi to 28 Marina Road, Lekki Lagos',
        distance: '30 KM',
        amount: '₦30,500.00',
        date: moment('2021-05-10').format('LL')
    },
    {
        id: '003',
        details: 'From 2 Alexander Road, Ikoyi to 28 Marina Road, Lekki Lagos',
        distance: '30 KM',
        amount: '₦30,500.00',
        date: moment('2021-05-10').format('LL')
    },
    {
        id: '002',
        details: 'From 2 Alexander Road, Ikoyi to 28 Marina Road, Lekki Lagos',
        distance: '30 KM',
        amount: '₦30,500.00',
        date: moment('2021-05-10').format('LL')
    }]