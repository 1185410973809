import { createNamedReducer } from "redux-named-reducers";
import { config } from "../../configs/app-config";
import { STATUS } from "../../constants/Enums";
import {
  ACCESS_TOKEN,
  GET_CARDS,
  GET_CARDS_SUCCESS,
  GET_CARDS_FAIL,
  INITIATE_PAYMENT,
  INITIATE_PAYMENT_SUCCESS,
  INITIATE_PAYMENT_FAIL,
  PENDING_PAYMENT,
  PENDING_PAYMENT_SUCCESS,
  PENDING_PAYMENT_FAIL,
  ENCRYPT_CARD,
  ENCRYPT_CARD_SUCCESS,
  ENCRYPT_CARD_FAIL,
  VALIDATE_ACCOUNT,
  VALIDATE_ACCOUNT_SUCCESS,
  VALIDATE_ACCOUNT_FAIL,
  GET_ACCOUNT,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAIL,
  CREATE_CARD,
  CREATE_CARD_SUCCESS,
  CREATE_CARD_FAIL,
  DELETE_CARD,
  DELETE_CARD_SUCCESS,
  DELETE_CARD_FAIL,
  SET_PRIMARY_CARD,
  SET_PRIMARY_CARD_SUCCESS,
  SET_PRIMARY_CARD_FAIL,
  GET_RECIPIENT,
  GET_RECIPIENT_SUCCESS,
  GET_RECIPIENT_FAIL,
  CREATE_RECIPIENT,
  CREATE_RECIPIENT_SUCCESS,
  CREATE_RECIPIENT_FAIL,
  GET_TOKEN,
  GET_TOKEN_SUCCESS,
  GET_TOKEN_FAIL,
  CREATE_BANK,
  CREATE_BANK_SUCCESS,
  CREATE_BANK_FAIL,
  SET_PRIMARY_BANK,
  SET_PRIMARY_BANK_SUCCESS,
  SET_PRIMARY_BANK_FAIL,
  DELETE_BANK,
  DELETE_BANK_SUCCESS,
  DELETE_BANK_FAIL,
  GET_BANK,
  GET_BANK_SUCCESS,
  GET_BANK_FAIL,
  GET_PAYMENT_HISTORY,
  GET_PAYMENT_HISTORY_SUCCESS,
  GET_PAYMENT_HISTORY_FAIL,
  GET_PAYOUTS,
  GET_PAYOUTS_SUCCESS,
  GET_PAYOUTS_FAIL,
  RESOLVE_BANK,
  RESOLVE_BANK_SUCCESS,
  RESOLVE_BANK_FAIL,
  GET_RECENT_TRANSACTION,
  GET_RECENT_TRANSACTION_SUCCESS,
  GET_RECENT_TRANSACTION_FAIL,
} from "../actions/PaymentAction";
const _ = require("underscore");

const initialState = {
  active_account: {},
  account_list: [],
  bank_list: [],
  card_list: [],
  recipient: {},
  payment_info: {},
  pending_payments: [],
  checked_pending: false,
  card_token: {},
  validation_info: {},
  loading_pay: false,
  loading_resolve: false,
  bank: {},
  paymentHistory: [],
  payouts: [],
  recent_transactions_loading: false,
  recent_transactions: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CARDS:
      return { ...state, loading_pay: true };
    case GET_CARDS_SUCCESS:
      return {
        ...state,
        loading_pay: false,
        card_list: action.payload.data.cards,
      };
    case GET_CARDS_FAIL:
      return { ...state, loading_pay: false, error: "Error sending Trip info" };

    case INITIATE_PAYMENT:
      return { ...state, loading_pay: true };
    case INITIATE_PAYMENT_SUCCESS:
      return {
        ...state,
        loading_pay: false,
        validation_info: action.payload.data,
      };
    case INITIATE_PAYMENT_FAIL:
      return { ...state, loading_pay: false, error: "Error sending Trip info" };

    case PENDING_PAYMENT:
      return { ...state, loading_pay: true };
    case PENDING_PAYMENT_SUCCESS:
      return {
        ...state,
        loading_pay: false,
        checked_pending: true,
        pending_payments: action.payload.data.charges,
      };
    case PENDING_PAYMENT_FAIL:
      return { ...state, loading_pay: false, error: "Error sending Trip info" };

    case VALIDATE_ACCOUNT:
      return { ...state, loading_pay: true };
    case VALIDATE_ACCOUNT_SUCCESS:
      let token = {};
      if (action.payload.data.status == 200) {
        token = action.payload.data.cardToken;
      }
      return { ...state, loading_pay: false, card_token: token };
    case VALIDATE_ACCOUNT_FAIL:
      return { ...state, loading_pay: false, error: "Error sending Trip info" };

    case GET_ACCOUNT:
      return { ...state, loading_pay: true };
    case GET_ACCOUNT_SUCCESS:
      let active = null;
      if (action.payload.data.banks) {
        active = _.findWhere(action.payload.data.banks, { active: true });
      }
      return {
        ...state,
        loading_pay: false,
        account_list: action.payload.data.banks,
        active_account: active,
      };
    case GET_ACCOUNT_FAIL:
      return { ...state, loading_pay: false, error: "Error sending Trip info" };

    case ENCRYPT_CARD:
      return { ...state, loading_pay: true };
    case ENCRYPT_CARD_SUCCESS:
      return { ...state, loading_pay: false };
    case ENCRYPT_CARD_FAIL:
      return { ...state, loading_pay: false, error: "Error sending Trip info" };

    case CREATE_CARD:
      return { ...state, loading_pay: true };
    case CREATE_CARD_SUCCESS:
      return { ...state, loading_pay: false };
    case CREATE_CARD_FAIL:
      return { ...state, loading_pay: false, error: "Error sending Trip info" };

    case DELETE_CARD:
      return { ...state, loading_pay: true };
    case DELETE_CARD_SUCCESS:
      return { ...state, loading_pay: false };
    case DELETE_CARD_FAIL:
      return { ...state, loading_pay: false, error: "Error sending Trip info" };

    case SET_PRIMARY_CARD:
      return { ...state, loading_pay: true };
    case SET_PRIMARY_CARD_SUCCESS:
      return { ...state, loading_pay: false, status: STATUS.ON_TRIP };
    case SET_PRIMARY_CARD_FAIL:
      return { ...state, loading_pay: false, error: "Error sending Trip info" };

    case GET_RECIPIENT:
      return { ...state, loading_pay: true };
    case GET_RECIPIENT_SUCCESS:
      return { ...state, loading_pay: false, status: STATUS.ON_TRIP };
    case GET_RECIPIENT_FAIL:
      return { ...state, loading_pay: false, error: "Error sending Trip info" };

    case CREATE_RECIPIENT:
      return { ...state, loading_pay: true };
    case CREATE_RECIPIENT_SUCCESS:
      return { ...state, loading_pay: false, status: STATUS.ON_TRIP };
    case CREATE_RECIPIENT_FAIL:
      return { ...state, loading_pay: false, error: "Error sending Trip info" };

    case GET_TOKEN:
      return { ...state, loading_pay: true };
    case GET_TOKEN_SUCCESS:
      return { ...state, loading_pay: false, status: STATUS.ON_TRIP };
    case GET_TOKEN_FAIL:
      return { ...state, loading_pay: false, error: "Error sending Trip info" };

    case CREATE_BANK:
      return { ...state, loading_pay: true };
    case CREATE_BANK_SUCCESS:
      return { ...state, loading_pay: false, bank: action.payload.data.bank };
    case CREATE_BANK_FAIL:
      return { ...state, loading_pay: false, error: "Creating bank failed!" };

    case SET_PRIMARY_BANK:
      return { ...state, loading_pay: true };
    case SET_PRIMARY_BANK_SUCCESS:
      return { ...state, loading_pay: false, status: STATUS.ON_TRIP };
    case SET_PRIMARY_BANK_FAIL:
      return {
        ...state,
        loading_pay: false,
        error: "Error setting active bank",
      };

    case DELETE_BANK:
      return { ...state, loading_pay: true };
    case DELETE_BANK_SUCCESS:
      return { ...state, loading_pay: false };
    case DELETE_BANK_FAIL:
      return { ...state, loading_pay: false, error: "Error deleting bank" };
    case GET_PAYMENT_HISTORY:
      return { ...state, loadingPayment: true };
    case GET_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        loadingPayment: false,
        paymentHistory: action.payload.data.payments,
      };
    case GET_PAYMENT_HISTORY_FAIL:
      return {
        ...state,
        loadingPayment: false,
        error: "Error sending getting payment history",
      };

    case GET_PAYOUTS:
      return { ...state, loadingPayouts: true };
    case GET_PAYOUTS_SUCCESS:
      return {
        ...state,
        loadingPayouts: false,
        payouts: action.payload.data.payouts,
      };
    case GET_PAYOUTS_FAIL:
      return {
        ...state,
        loadingPayouts: false,
        error: "Error sending getting payouts",
      };

    case GET_BANK:
      return { ...state, loadingBank: true };
    case GET_BANK_SUCCESS:
      return { ...state, loadingBank: false, bank: action.payload.data.bank };
    case GET_BANK_FAIL:
      return {
        ...state,
        loadingBank: false,
        error: "Error sending getting bank",
      };

    case GET_RECENT_TRANSACTION:
      return { ...state, recent_transactions_loading: true };
    case GET_RECENT_TRANSACTION_SUCCESS:
      return { ...state, recent_transactions_loading: false, recent_transactions: action.payload.data.transactions };
    case GET_RECENT_TRANSACTION_FAIL:
      return { ...state, recent_transactions_loading: false, error: "Error getting transactions" };

    default:
      return state;
  }
}

export const PaymentModule = createNamedReducer({
  moduleName: "PaymentModule",
  reducer: reducer,
});
