import React, { useEffect, useState } from "react";
import DashboardCard from "./DashboardCard";
import { connect } from "react-redux";
import { reduxFunctions } from "../../helper/ReduxHelper";
import moment from "moment";
import { distancePerDay, revenuePerDay, revenuePerWeek, tripPerWeek } from "../../helper/DataHelper";
import { toast } from "react-toastify";

const DailyDis = (props) => {
  const [data, setData] = useState([{ y: 0, x: moment().valueOf() }]);

  const getPaymentHistory = async () => {
    await props
      .getPaymentHistory()
      .then((res) => {
        setData(distancePerDay(res.payload.data.payments));
      })
      .catch((error) => {
        // toast.error('Error getting distance data!');
        return error;
      });
  };

  useEffect(() => {
    getPaymentHistory();
  }, []);

  return (
    <DashboardCard
      number={data[data.length - 1] ? `${Number.parseFloat(data[data.length - 1].y).toFixed(2)}KM` : "0KM"}
      info="Daily distance covered"
      data={data}
      head="Daily distance covered"
      title="distance"
      loading={props.loadingPayment}
    />
  );
};

const mapStateToProps = ({ user, paymentHistory, loadingPayment }) => ({ user, paymentHistory, loadingPayment });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(DailyDis);
