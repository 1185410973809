import React, { useEffect, useState } from "react";
import { Button, Table, Modal, Form, Input, Spin, Avatar, Image, Select, Menu, Dropdown } from "antd";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Empty from "../assets/svgs/empty.svg";
import loadingIcon from "../components/common/loadingIcon";
import { connect } from "react-redux";
import { reduxFunctions } from "../helper/ReduxHelper";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import CustomInput from "../components/common/Input";
import { USER_STATUSES } from "../constants/Enums";
import empty from "../assets/empty.jpeg";
import "../styles/main.css";
import { toast } from "react-toastify";
import { authenticatedCompany } from "../helper/AuthHelper";

const { Option } = Select;

const DriverList = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState(props.drivers);
  const [vehicles, setVehicles] = useState(props.vehicles);
  const [displayedData, setDisplayedData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState(authenticatedCompany());

  const handleSearchChange = ({ currentTarget: input }) => {
    setSearchInput(input.value);
  };

  const submit = (driverId, vehicleId, driverVehicle, vehicle) => {
    const currentDriverId = vehicle.driver;
    const getCurrentDriverDetails = () => data.find((a) => a._id == currentDriverId);
    const getPotentialDriverDetails = () => data.find((a) => a._id == driverId);

    const message =
      getCurrentDriverDetails() && getCurrentDriverDetails()._id !== driverId
        ? `This vehicle is already assigned to ${
            getCurrentDriverDetails()?.full_name
          }, are you sure you want to reassign it to ${getPotentialDriverDetails()?.full_name}`
        : currentDriverId && currentDriverId == driverId
        ? `Are you sure you want to unassign this vehicle from ${getPotentialDriverDetails()?.full_name}`
        : `Are you sure you want to assign this vehicle to ${getPotentialDriverDetails()?.full_name}`;

    confirmAlert({
      title: "Confirm to submit",
      message: message,
      buttons: [
        {
          label: "Yes",
          onClick: () => _assignVehicle(driverId, vehicleId),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const getDrivers = async () => {
    if (company) {
      await props
        .getCompanyDrivers(company._id)
        .then((res) => {
          const newData = [...res.payload.data.drivers];
          newData.forEach((driver) => {
            const newArray = driver.full_name.split(" ");
            if (newArray[0] === "undefined") {
              driver.full_name = newArray[1];
            }
            if (newArray[1] === "undefined") {
              driver.full_name = newArray[0];
            }
            if (newArray[0] !== "undefined" && newArray[1] !== "undefined") {
              driver.full_name = `${newArray[0]} ${newArray[1]}`;
            }
            if (driver.profile_picture) {
              driver.image = driver.profile_picture.original;
            }
          });

          setData(newData);
        })
        .catch((error) => {
          toast.error("Error getting company drivers");
          return error;
        });
    } else {
      return "No company found";
    }
  };

  const _getAvailableDriver = () => {
    if (company) {
      props
        .getAvailableVehicles(company?._id)
        .then((res) => {
          setVehicles(res.payload.data.vehicles);
        })
        .catch((error) => {
          toast.error("Error getting available vehicles");
          return error;
        });
    }
  };

  const getPendingInvites = () => {
    if (company) {
      props
        .getPendingDrivers(company?._id)
        .then((res) => {
          // setVehicles(res.payload.data.vehicles);
        })
        .catch((error) => {
          toast.error("Error getting pending invites");
          return error;
        });
    }
  };

  const _assignVehicle = (driver_id, vehicle_id) => {
    props
      .assignVehicle(company?._id, driver_id, vehicle_id)
      .then((res) => {
        toast.success("Vehicle assignment successful");
        getDrivers();
        _getAvailableDriver();
      })
      .catch((error) => {
        toast.error("Error assigning vehicle");
        return error;
      });
  };
  useEffect(() => {
    setData(props.drivers);
  }, [props]);

  useEffect(() => {
    getDrivers();
    getPendingInvites();
  }, [company]);
  useEffect(() => {
    _getAvailableDriver();
  }, [company]);

  useEffect(() => {
    const filteredData = data.filter((driver) => driver.status === USER_STATUSES.VERIFIED);
    const newData = filteredData.filter((row) => {
      return row.full_name.toLowerCase().includes(searchInput.toLowerCase());
    });
    searchInput.length === 0 ? setDisplayedData(data) : setDisplayedData(newData);
  }, [searchInput]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleAdd = async () => {
    setLoading(true);
    await props
      .addDriverToCompany(company._id, email)
      .then((res) => {
        setLoading(false);
        toast.success("Invitation sent successfully!");
        setEmail("");
      })
      .catch((error) => {
        setLoading(false);
        setEmail("");
        toast.error(error.error.response.data.message);
        return error;
      });
    setIsModalVisible(false);
  };

  const handleResetInvite = (email) => {
    setLoading(true);
    props
      .resendInvite(company._id, email)
      .then((res) => {
        toast.success("Invitation resent successfully!");
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Error resending invite");
        setLoading(false);
        return error;
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleEmail = ({ currentTarget }) => {
    setEmail(currentTarget.value);
  };

  const EmptyIcon = <img src={Empty} alt="Empty list" style={{ width: "100px" }} />;

  const driverListColumnsVer = [
    {
      title: "",
      dataIndex: "image",
      key: "image",
      // responsive: ['md'],
      render: (text, record) => <Avatar shape="square" src={text ? <Image src={text} /> : <Image src={empty} />} />,
    },
    {
      title: "Full name",
      dataIndex: "full_name",
      key: "full_name",
      // responsive: ['md'],
      render: (text, record) => (
        <p className="driver-list-name" onClick={() => props.history.push(`/app/driver/${record._id}`)}>
          {text}
        </p>
      ),
    },
    {
      title: "Phone number",
      dataIndex: "phone_number",
      key: "phone_number",
      responsive: ["md"],
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      responsive: ["md"],
    },
    // {
    //     title: 'Assign Truck',
    //     dataIndex: 'assign_truck',
    //     key: 'assign_truck',
    //     // responsive: ['md'],
    //     render: () =>     <Select defaultValue={vehicles[0]._id} style={{ width: 120 }}>
    //         {vehicles.map(vehicle => <Option key={vehicle._id} value={vehicle._id}>{vehicle.make} ({vehicle.plate_number})</Option>)}
    //         {/*<Option value="jack">Jack</Option>*/}
    //         {/*<Option value="lucy">Lucy</Option>*/}
    //     </Select>
    // },
    {
      title: "Truck",
      dataIndex: "assign_truck",
      key: "assign_truck",
      // responsive: ['md'],
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              {vehicles?.length === 0 ? (
                <p>No available vehicle</p>
              ) : (
                vehicles.map((vehicle, i) => (
                  <Menu.Item
                    key={record._id}
                    style={{ fontWeight: "300", color: "#252525" }}
                    onClick={() => submit(record._id, vehicle._id, record.vehicle, vehicle)}
                  >
                    {vehicle._id === record.vehicle?.id
                      ? "Unassign"
                      : `${vehicle.make} ${vehicle.model} (${vehicle.plate_number})`}
                  </Menu.Item>
                ))
              )}
            </Menu>
          }
          placement="bottomCenter"
          disabled={record.is_driver_booked}
        >
          {record.vehicle ? (
            record.is_driver_booked ? (
              <p style={{ color: record?.is_driver_booked ? "gray" : "black" }}>Booked Driver</p>
            ) : (
              <p>
                {record.vehicle.make} {record.vehicle.model} ({record.vehicle.plate_number})<DownOutlined />
              </p>
            )
          ) : (
            <p>
              No truck <DownOutlined />
            </p>
          )}
        </Dropdown>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // responsive: ['md'],
    },
  ];

  const driverListColumns = [
    {
      title: "",
      dataIndex: "image",
      key: "driver-image",
      // responsive: ['md'],
      render: (text) => <Avatar shape="square" src={text ? <Image src={text} /> : <Image src={empty} />} />,
    },
    {
      title: "Full name",
      dataIndex: "full_name",
      key: "driver-full_name",
      render: (text, record) => (
        <p className="driver-list-name" onClick={() => props.history.push(`/app/driver/${record._id}`)}>
          {text}
        </p>
      ),
    },
    {
      title: "Phone number",
      dataIndex: "phone_number",
      key: "driver-phone_number",
      responsive: ["md"],
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "driver-email",
      responsive: ["md"],
    },
    {
      title: "Invite",
      dataIndex: "resend_invite",
      key: "resend_invite",
      render: (text, record) => (
        <Button className="add-driver all-button" type="primary" onClick={() => handleResetInvite(record.email)}>
          Resend Invite
        </Button>
      ),
    },
    // {
    //     title: '',
    //     dataIndex: 'arrow',
    //     key: 'arrow',
    //     render: text => <RightOutlined />
    //     // responsive: ['md'],
    // },
  ];

  return (
    <div>
      <h2 className="header">Driver List</h2>
      <div className="inner-container">
        <div className="driver-search-container">
          <CustomInput
            placeholder="Search"
            size="large"
            value={searchInput}
            className="past-trip-input"
            prefix={<SearchOutlined />}
            handleChange={handleSearchChange}
          />
          <div className={"add-driver-container"}>
            <p onClick={() => props.history.push("/app/signup-driver")}>Sign Up Driver</p>
            <Button size={"large"} className="add-driver all-button" type="primary" onClick={showModal}>
              Add Driver
            </Button>
          </div>
        </div>
        <div className="table-container">
          <Table
            className="table"
            locale={{ emptyText: EmptyIcon }}
            columns={driverListColumnsVer}
            loading={props.loadingDrivers}
            dataSource={searchInput ? displayedData : data.filter((driver) => driver.status === USER_STATUSES.VERIFIED)}
            size="large"
            pagination={false}
          />
        </div>
      </div>
      <div className="inner-container pending-driver">
        <h2>Pending Invites</h2>
        <div className="table-container">
          <Table
            className="table"
            locale={{ emptyText: EmptyIcon }}
            columns={driverListColumns}
            loading={props.loadingPendingDrivers}
            dataSource={props.pendingDrivers?.map((el, i) => el?.employee)}
            size="large"
            pagination={false}
          />
        </div>
      </div>
      <Modal
        title="Add New Driver"
        className="add-driver-modal"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button className="modal-button" key="back" onClick={handleCancel} size={"large"}>
            Cancel
          </Button>,
          <Button key="submit" className="modal-button" type="primary" onClick={handleAdd} size={"large"}>
            {loading ? <Spin indicator={loadingIcon} /> : <span>Add</span>}
          </Button>,
        ]}
      >
        <Form layout="vertical" className="modal-form">
          <Form.Item
            label="Driver Email Address"
            name="email_address"
            rules={[{ message: "Please enter driver email", type: "email" }]}
            className="modal-label"
          >
            <Input
              className="modal-input"
              value={email}
              onChange={handleEmail}
              placeholder="Enter a registered driver's email..."
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ drivers, vehicles, loadingDrivers, loadingPendingDrivers, pendingDrivers }) => ({
  drivers,
  vehicles,
  loadingDrivers,
  loadingPendingDrivers,
  pendingDrivers,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(DriverList);
