import styled from "styled-components";
import ReactSnackBar from "react-js-snackbar";
import { Form, Input, Radio, Button, Spin, InputNumber } from "antd";
import React, { useState, useEffect } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { reduxFunctions } from "../../helper/ReduxHelper";
import { connect } from "react-redux";
import moment from "moment";
import loadingIcon from "../common/loadingIcon";
import { toast } from "react-toastify";

const CustomerProfile = ({ data, handleChange, setData, setCurrentStep, createCost, loadingCost }) => {
  const [notificationState, setNotificationState] = useState({
    show: false,
    showing: false,
  });

  const show = () => {
    if (notificationState.showing) return;

    setNotificationState({ show: true, showing: true });
    setTimeout(() => {
      setNotificationState({ show: false, showing: false });
    }, 2000);
  };

  const tripData = {
    start_location: [data?.startCoordinates?.lng, data?.startCoordinates?.lat],
    end_location: [data?.endCoordinates?.lng, data?.endCoordinates?.lat],
    item_size: data?.item_size,
    trip_category: data?.trip_category,
    offloading_duration: 86400000 * +data?.offloading_duration,
    is_container_owner: data?.is_container_owner == "yes" ? true : false,
    tdo_valid_until: moment().add(3, "days"),
    start_address: data.startAddress,
    end_address: data.endAddress,
    drop_off_address: data.dropOffAddress,
    tonnage: data?.tonnage,
    container_number: data?.container_number,
    offline_price: +data?.offline_price,
  };

  const getCostId = async () => {
    if (!data.startCoordinates.lat || !data.endCoordinates.lat || !data.trip_category) {
      return;
    }

    try {
      const res = await createCost(tripData);
      if (res?.error) {
        throw new Error(res?.error);
      }
      setData({
        ...data,
        cost_id: res?.payload?.data?.cost?._id,
        estimated_price: res?.payload?.data?.cost?.price,
        estimated_upperBound: res?.payload?.data?.cost?.price_upper_bound,
      });
      setCurrentStep(3);
      return;
    } catch (error) {
      toast.error(error.error.response.data.message);
      return error;
    }
  };

  const handleContinue = () => {
    if (!data.customerFullname || !data.customerEmail || !data.offline_price) {
      show();
      return;
    }
    getCostId();
  };

  return (
    <StyledForm layout={"vertical"}>
      <ReactSnackBar Icon={<AiOutlineInfoCircle />} Show={notificationState.show}>
        Fill and select all fields to continue!
      </ReactSnackBar>
      <Form.Item label="Full name" name="customerFullname" required={false} className="input-item">
        <div>
          <Input
            name="customerFullname"
            value={data.customerFullname}
            onChange={handleChange}
            size={"large"}
            placeholder="Enter full name"
          />
        </div>
      </Form.Item>
      <Form.Item type={"email"} label="Email" name="customerEmail" required={false} className="input-item">
        <div>
          <Input
            type={"email"}
            name="customerEmail"
            value={data.customerEmail}
            onChange={handleChange}
            size={"large"}
            placeholder="Enter email address"
          />
        </div>
      </Form.Item>
      <Form.Item label="Input Price (₦)" name="offline_price" required={false} className="input-item">
        {/* <div>
          <Input
            name="offline_price"
            value={data.offline_price}
            onChange={handleChange}
            size={"large"}
            placeholder=""
          />
        </div> */}
        <div>
          <InputNumber
            name="offline_price"
            value={data.offline_price}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
            onChange={(value) => setData({ ...data, offline_price: value })}
            placeholder="Enter offline price"
            controls={false}
            style={{ width: "100%" }}
          />
        </div>
      </Form.Item>

      <div className="btn-container">
        <Button onClick={() => setCurrentStep(1)}>Back</Button>
        <Button type={"primary"} onClick={handleContinue}>
          {loadingCost ? <Spin indicator={loadingIcon} /> : "Next"}
        </Button>
      </div>
    </StyledForm>
  );
};

const StyledForm = styled(Form)`
  input {
    border-radius: 5px;
    height: 50px;
  }

  .btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
  }

  button {
    width: 150px;
    height: 50px;
    border-radius: 5px;
  }

  .sub-title {
    color: #344054;
    font-weight: 600;
  }

  label {
    font-weight: bold;
    font-size: 18px;
    color: ${({ theme }) => theme?.colors?.secondary};
    color: #344054;
  }
`;

const FieldContainer = styled.div`
  display: flex;
  margin-bottom: 50px;
  justify-content: space-between;

  .radio {
    width: 20px;
  }

  .card-container {
    width: 45%;
    //margin-right: 30px;
  }

  @media (max-width: 900px) {
    justify-content: space-between;

    .card-container {
      width: 48%;
      margin-right: 0;
    }
  }

  @media (max-width: 800px) {
    display: block;

    .card-container {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;

const mapStateToProps = ({ createCost, loadingCost, available_drivers }) => ({
  createCost,
  loadingCost,
  available_drivers,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(CustomerProfile);
