import React, { useEffect, useState } from "react";
import { Button, Input, message, Spin, Upload } from "antd";
import { toast } from "react-toastify";
import styled from "styled-components";
import { authenticatedCompany } from "../helper/AuthHelper";
import { reduxFunctions } from "../helper/ReduxHelper";
import { connect } from "react-redux";
import { DOCUMENT_TYPES, VERIFICATION_STATUSES } from "../constants/Enums";
import loadingIcon from "./common/loadingIcon";

const { Dragger } = Upload;

const initialState = { document_type: DOCUMENT_TYPES.CAC, document_id: "", document_image: "" };

const DocumentVerificationModal = (props) => {
  const [kycData, setKycData] = useState(initialState);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [s3ImageUrl, setS3ImageUrl] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  const company = authenticatedCompany();

  const cac_document = company?.documents?.find((el) => el.document_type === DOCUMENT_TYPES.CAC);

  const documentTypeOptions = [
    { label: "CAC Document", value: "cac" },
    { label: "Drivers Document", value: "driver" },
  ];

  const handleDocumentType = (value) => {
    setKycData({ ...kycData, type: value });
  };

  const beforeUpload = async (file) => {
    const fileType = file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf";
    if (!fileType) {
      message.error("You can only upload an acceptable file");
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toast.error("Image should not exceed 2MB");
      return;
    }
    await props
      .getUploadUrl(file.type)
      .then(async (res) => {
        let url = res.payload.data.upload.url;
        let path = res.payload.data.upload.path;
        setUploadUrl(url);
        setS3ImageUrl(path);
        return fileType && isLt2M;
      })
      .catch((e) => {
        message.error("Image should not exceed 2MB");
        return;
      });
  };

  const handleFileChange = (info) => {
    setImageUrl(null);
    setFileList([]);
    // data.profile_picture = null;
    let file = info.file;
    if (file.status === "removed") {
      setImageUrl(null);
      setFileList([]);
    } else if (file.status === "uploading") {
      setFileList([file]);
      // setLoadingImage(true);
    } else if (file.status === "done") {
      let url = s3ImageUrl;
      setFileList([{ uid: "current", name: file.name, status: "done", url: url }]);
      setImageUrl(url);
      // setLoadingImage(false);
    }
  };

  const handleUpload = async ({ file, onSuccess, onError, onProgress }) => {
    const xhr = new XMLHttpRequest();
    // S3 requires PUT method!
    xhr.open("PUT", uploadUrl);
    xhr.onreadystatechange = async () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          // Calls the update prop
          setKycData({ ...kycData, document_image: s3ImageUrl });
          setImageUrl(s3ImageUrl);
          onSuccess(null, file);
        } else {
          message.error("Upload Failed");
        }
      }
    };
    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        onProgress({ percent: Math.round((e.loaded / e.total) * 100).toFixed(2) }, file);
      }
    };
    xhr.send(file);
  };

  const draggerProps = {
    name: "file",
    beforeUpload: beforeUpload,
    multiple: true,
    action: uploadUrl,
    onChange: handleFileChange,
    customRequest: (options) => handleUpload(options),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!kycData?.document_id || !kycData?.document_image || !kycData?.document_type)
      return toast.error("All fields are required");

    const payload = { ...company, documents: [...company.documents, kycData] };

    setLoading(true);
    await props
      .updateCompany(payload)
      .then(async (res) => {
        toast.success("Document submitted successfully");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Error submitting document");
      });
  };

  const getUserCompany = async () => {
    await props
      .getCompany()
      .then((res) => {
        const userCompany = { ...res?.payload?.data?.companies[0] };
        localStorage.setItem("company", JSON.stringify(userCompany));
      })
      .catch((error) => {
        return error;
      });
  };

  useEffect(() => {
    if (cac_document?.status == VERIFICATION_STATUSES.IN_REVIEW) {
      getUserCompany();
    }
  }, []);

  const instructions = [
    "Please note that only verified companies will be able to access our services.",
    "Our team will review your CAC document and get back to you within 24-48 hours.",
    "If you have any questions or concerns, please contact our customer support team.",
  ];

  return (
    <Container>
      {cac_document?.status === VERIFICATION_STATUSES.IN_REVIEW ? (
        <div className="content-container">
          <img src="/verification-icon.svg" alt="KYC" />
          <h2>Verification in Progress</h2>

          <div className="instruction-container">
            {instructions.map((instruction, i) => (
              <ul key={i}>
                <li>{instruction}</li>
              </ul>
            ))}
          </div>
        </div>
      ) : (
        <div className="content-container">
          <img src="/kyc-icon.svg" alt="KYC" />
          <h2>Company CAC Document</h2>
          <p>Please upload a copy of your company CAC document for verification</p>

          <form className="form-container">
            {/* <div className="input-section">
              <h2 className="sub-title">Document Type</h2>
              <div className="select-container">
                <CustomSelect
                  options={documentTypeOptions}
                  size="large"
                  handleChange={handleDocumentType}
                  value={kycData.type}
                  bordered={false}
                  customStyle={{ width: "100%", fontSize: "14px" }}
                />
              </div>
            </div> */}

            {/* <div className="input-section">
              <h2 className="sub-title">Company Name</h2>
              <Input
                name="id"
                value={kycData.company_name}
                onChange={(e) => setKycData({ ...kycData, company_name: e.target.value })}
                size={"large"}
                style={{ border: "1px solid #d0d5dd", borderRadius: "8px", fontSize: "14px" }}
              />
            </div> */}

            <div className="input-section">
              <h2 className="sub-title">Company Registration Number</h2>
              <Input
                name="id"
                value={kycData.document_id}
                onChange={(e) => setKycData({ ...kycData, document_id: e.target.value })}
                size={"large"}
                style={{ border: "1px solid #d0d5dd", borderRadius: "8px", fontSize: "14px" }}
              />
            </div>

            <div className="input-section">
              <h2 className="sub-title">Upload CAC Document</h2>
              <Dragger {...draggerProps}>
                <p className="ant-upload-text">
                  <span style={{ color: "#e27626", fontSize: "14px" }}>Click or upload</span> or drag and drop
                </p>
                <p className="ant-upload-hint" style={{ fontSize: "12px" }}>
                  PNG, JPG or PDF(max 2MB)
                </p>
              </Dragger>
            </div>

            <div style={{ marginTop: "20px" }}>
              <Button htmlType="submit" type="primary" onClick={handleSubmit}>
                {loading ? <Spin indicator={loadingIcon} /> : "Submit"}
              </Button>
            </div>
          </form>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;

  .content-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .instruction-container {
    display: flex;
    flex-direction: column;
    gap: 6px;

    ul {
      list-style-position: inside;
      margin: 0;
    }

    li {
      color: #8a94a6;
    }
  }

  .form-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .select-container {
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    width: 100%;
  }

  .input-section {
    text-align: left;
    font-size: 16px;

    select {
      width: 100%;
    }
  }

  .sub-title {
    color: #344054;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
  }

  img {
    max-height: 80px;
  }

  h2 {
    margin: 5px 0 0px 0;
    color: #000000;
    padding: 0;
  }

  p {
    margin: 3px 0;
    color: #667085;
  }

  button {
    width: 100%;
    height: 42px;
    border-radius: 5px;
  }
`;

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentVerificationModal);
