import { combineReducers } from 'redux'
import {MainModule} from './reducers/Reducer'
import concatenateReducers from "redux-concatenate-reducers";
import {PaymentModule} from "./reducers/PaymentReducer";


const rootReducer = concatenateReducers([
    MainModule.reducer,
    PaymentModule.reducer,
]);

export default rootReducer
