import { GET_USER, GET_USER_FAIL, GET_USER_SUCCESS } from "./Action";
import { config } from "../../configs/app-config";

export const ACCESS_TOKEN = "access_token";

export const GET_CARDS = "hawker-customer/Redux/GET_CARDS";
export const GET_CARDS_SUCCESS = "my-awesome-app/Redux/GET_CARDS_SUCCESS";
export const GET_CARDS_FAIL = "my-awesome-app/Redux/GET_CARDS_FAIL";

export const INITIATE_PAYMENT = "hawker-customer/Redux/INITIATE_PAYMENT";
export const INITIATE_PAYMENT_SUCCESS = "my-awesome-app/Redux/INITIATE_PAYMENT_SUCCESS";
export const INITIATE_PAYMENT_FAIL = "my-awesome-app/Redux/INITIATE_PAYMENT_FAIL";

export const PENDING_PAYMENT = "hawker-customer/Redux/PENDING_PAYMENT";
export const PENDING_PAYMENT_SUCCESS = "my-awesome-app/Redux/PENDING_PAYMENT_SUCCESS";
export const PENDING_PAYMENT_FAIL = "my-awesome-app/Redux/PENDING_PAYMENT_FAIL";

export const ENCRYPT_CARD = "hawker-customer/Redux/ENCRYPT_CARD";
export const ENCRYPT_CARD_SUCCESS = "my-awesome-app/Redux/ENCRYPT_CARD_SUCCESS";
export const ENCRYPT_CARD_FAIL = "my-awesome-app/Redux/ENCRYPT_CARD_FAIL";

export const VALIDATE_ACCOUNT = "hawker-customer/Redux/VALIDATE_ACCOUNT";
export const VALIDATE_ACCOUNT_SUCCESS = "my-awesome-app/Redux/VALIDATE_ACCOUNT_SUCCESS";
export const VALIDATE_ACCOUNT_FAIL = "my-awesome-app/Redux/VALIDATE_ACCOUNT_FAIL";

export const GET_ACCOUNT = "hawker-customer/Redux/GET_ACCOUNT";
export const GET_ACCOUNT_SUCCESS = "my-awesome-app/Redux/GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_FAIL = "my-awesome-app/Redux/GET_ACCOUNT_FAIL";

export const CREATE_CARD = "hawker-customer/Redux/CREATE_CARD";
export const CREATE_CARD_SUCCESS = "my-awesome-app/Redux/CREATE_CARD_SUCCESS";
export const CREATE_CARD_FAIL = "my-awesome-app/Redux/CREATE_CARD_FAIL";

export const DELETE_CARD = "hawker-customer/Redux/DELETE_CARD";
export const DELETE_CARD_SUCCESS = "my-awesome-app/Redux/DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAIL = "my-awesome-app/Redux/DELETE_CARD_FAIL";

export const DELETE_BANK = "hawker-customer/Redux/DELETE_BANK";
export const DELETE_BANK_SUCCESS = "my-awesome-app/Redux/DELETE_BANK_SUCCESS";
export const DELETE_BANK_FAIL = "my-awesome-app/Redux/DELETE_BANK_FAIL";

export const SET_PRIMARY_CARD = "hawker-customer/Redux/SET_PRIMARY_CARD";
export const SET_PRIMARY_CARD_SUCCESS = "my-awesome-app/Redux/SET_PRIMARY_CARD_SUCCESS";
export const SET_PRIMARY_CARD_FAIL = "my-awesome-app/Redux/SET_PRIMARY_CARD_FAIL";

export const GET_RECIPIENT = "hawker-customer/Redux/GET_RECIPIENT";
export const GET_RECIPIENT_SUCCESS = "my-awesome-app/Redux/GET_RECIPIENT_SUCCESS";
export const GET_RECIPIENT_FAIL = "my-awesome-app/Redux/GET_RECIPIENT_FAIL";

export const CREATE_RECIPIENT = "hawker-customer/Redux/CREATE_RECIPIENT";
export const CREATE_RECIPIENT_SUCCESS = "my-awesome-app/Redux/CREATE_RECIPIENT_SUCCESS";
export const CREATE_RECIPIENT_FAIL = "my-awesome-app/Redux/CREATE_RECIPIENT_FAIL";

export const GET_TOKEN = "hawker-customer/Redux/GET_TOKEN";
export const GET_TOKEN_SUCCESS = "my-awesome-app/Redux/GET_TOKEN_SUCCESS";
export const GET_TOKEN_FAIL = "my-awesome-app/Redux/GET_TOKEN_FAIL";

export const CREATE_BANK = "hawker-customer/Redux/CREATE_BANK";
export const CREATE_BANK_SUCCESS = "my-awesome-app/Redux/CREATE_BANK_SUCCESS";
export const CREATE_BANK_FAIL = "my-awesome-app/Redux/CREATE_BANK_FAIL";

export const SET_PRIMARY_BANK = "hawker-customer/Redux/SET_PRIMARY_BANK";
export const SET_PRIMARY_BANK_SUCCESS = "my-awesome-app/Redux/SET_PRIMARY_BANK_SUCCESS";
export const SET_PRIMARY_BANK_FAIL = "my-awesome-app/Redux/SET_PRIMARY_BANK_FAIL";

export const GET_PAYMENT_HISTORY = "KAGO/Redux/GET_PAYMENT_HISTORY";
export const GET_PAYMENT_HISTORY_SUCCESS = "KAGO/Redux/GET_PAYMENT_HISTORY_SUCCESS";
export const GET_PAYMENT_HISTORY_FAIL = "KAGO/Redux/GET_PAYMENT_HISTORY_FAIl";

export const GET_PAYOUTS = "KAGO/Redux/GET_PAYOUTS";
export const GET_PAYOUTS_SUCCESS = "KAGO/Redux/GET_PAYOUTS_SUCCESS";
export const GET_PAYOUTS_FAIL = "KAGO/Redux/GET_PAYOUTS_FAIl";

export const GET_BANK = "KAGO/Redux/GET_BANK";
export const GET_BANK_SUCCESS = "KAGO/Redux/GET_BANK_SUCCESS";
export const GET_BANK_FAIL = "KAGO/Redux/GET_BANK_FAIL";

export const FUND_WALLET = "KAGO/Redux/FUND_WALLET";
export const FUND_WALLET_SUCCESS = "KAGO/Redux/FUND_WALLET_SUCCESS";
export const FUND_WALLET_FAIL = "KAGO/Redux/FUND_WALLET_FAIL";

export const GET_RECENT_TRANSACTION = "KAGO/Redux/GET_RECENT_TRANSACTION";
export const GET_RECENT_TRANSACTION_SUCCESS = "KAGO/Redux/GET_RECENT_TRANSACTION_SUCCESS";
export const GET_RECENT_TRANSACTION_FAIL = "KAGO/Redux/GET_RECENT_TRANSACTION_FAIL";

const baseUrl = process.env.REACT_APP_BASEURL;

export function getMe() {
  return {
    types: [GET_USER, GET_USER_SUCCESS, GET_USER_FAIL],
    payload: {
      request: {
        method: "GET",
        url: "/users/me",
        baseURL: config.FLUTTER_URL,
      },
    },
  };
}

export function getPaymentHistory() {
  return {
    types: [GET_PAYMENT_HISTORY, GET_PAYMENT_HISTORY_SUCCESS, GET_PAYMENT_HISTORY_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `/payments/history`,
      },
    },
  };
}

export function getPayouts() {
  return {
    types: [GET_PAYOUTS, GET_PAYOUTS_SUCCESS, GET_PAYOUTS_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `/payments/payouts`,
      },
    },
  };
}

export function getCardList() {
  return {
    types: [GET_CARDS, GET_CARDS_SUCCESS, GET_CARDS_FAIL],
    payload: {
      request: {
        method: "GET",
        url: "/users/me/cards",
      },
    },
  };
}

export function getPendingPayments() {
  return {
    types: [PENDING_PAYMENT, PENDING_PAYMENT_SUCCESS, PENDING_PAYMENT_FAIL],
    payload: {
      request: {
        method: "GET",
        url: "/users/me/charges?state=PENDING",
      },
    },
  };
}

export function initiatePayment(charge) {
  return {
    types: [INITIATE_PAYMENT, INITIATE_PAYMENT_SUCCESS, INITIATE_PAYMENT_FAIL],
    payload: {
      request: {
        method: "PUT",
        url: "/payment",
        baseURL: config.GLADE_URL,
        headers: {
          key: config.MERCHANT_KEY,
          mid: config.MERCHANT_ID,
        },
        data: charge,
      },
    },
  };
}

export function otpValidation(creditcard) {
  return {
    types: [VALIDATE_ACCOUNT, VALIDATE_ACCOUNT_SUCCESS, VALIDATE_ACCOUNT_FAIL],
    payload: {
      request: {
        method: "PUT",
        url: "/payment",
        baseURL: config.GLADE_URL,
        headers: {
          key: config.MERCHANT_KEY,
          mid: config.MERCHANT_ID,
        },
        data: {
          action: "validate",
          txnRef: creditcard.txnRef,
          otp: creditcard.otp,
        },
      },
    },
  };
}

export function createCard(creditcard) {
  return {
    types: [CREATE_CARD, CREATE_CARD_SUCCESS, CREATE_CARD_FAIL],
    payload: {
      request: {
        method: "POST",
        url: "/cards",
        data: creditcard,
      },
    },
  };
}

export function deleteCard(id) {
  return {
    types: [DELETE_CARD, DELETE_CARD_SUCCESS, DELETE_CARD_FAIL],
    payload: {
      request: {
        method: "DELETE",
        url: "/cards/" + id,
        data: {
          id: id,
        },
      },
    },
  };
}

export function setActiveCard(id) {
  return {
    types: [SET_PRIMARY_CARD, SET_PRIMARY_CARD_SUCCESS, SET_PRIMARY_CARD_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `/users/me/cards/${id}/active`,
      },
    },
  };
}

export function getAccounts() {
  return {
    types: [GET_ACCOUNT, GET_ACCOUNT_SUCCESS, GET_ACCOUNT_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `/users/me/banks`,
      },
    },
  };
}

export function setActiveBank(id) {
  return {
    types: [SET_PRIMARY_BANK, SET_PRIMARY_BANK_SUCCESS, SET_PRIMARY_BANK_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `/users/me/banks/${id}/active`,
      },
    },
  };
}

export function createBank(bank) {
  return {
    types: [CREATE_BANK, CREATE_BANK_SUCCESS, CREATE_BANK_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `/banks`,
        data: bank,
      },
    },
  };
}

export function deleteBank(id) {
  return {
    types: [DELETE_BANK, DELETE_BANK_SUCCESS, DELETE_BANK_FAIL],
    payload: {
      request: {
        method: "DELETE",
        url: `/banks/${id}`,
        data: { id },
      },
    },
  };
}

export function getBank(id) {
  return {
    types: [GET_BANK, GET_BANK_SUCCESS, GET_BANK_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `/users/me/banks/${id}`,
      },
    },
  };
}

export function fundWallet(companyId, data) {
  return {
    types: [FUND_WALLET, FUND_WALLET_SUCCESS, FUND_WALLET_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `/companies/${companyId}/fund_wallet`,
        data,
      },
    },
  };
}

export function getRecentTransactions(id, limit) {
  return {
    types: [GET_RECENT_TRANSACTION, GET_RECENT_TRANSACTION_SUCCESS, GET_RECENT_TRANSACTION_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `/transactions?wallet_id=${id}&limit=${limit}&sort=transaction_date`,
      },
    },
  };
}
