import React from 'react';
import TotalRevenue from "./TotalRevenue";
import '../../styles/main.css'
import DayRevenue from "./DayRevenue";
import DayTrips from "./DayTrips";
import DayDistance from "./DayDistance";
import Breakdowns from "./Breakdowns";


const Insights = () => {
    return (
        <div>
            <h2 className='header'>Insights</h2>
            <TotalRevenue />
            <DayRevenue />
            <DayTrips />
            <DayDistance />
            {/*<Breakdowns />*/}
        </div>
    )
}

export default Insights;