import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Empty from "../assets/svgs/empty.svg";
import { reduxFunctions } from "../helper/ReduxHelper";
import { Table, Tag, Space, Badge, Pagination, DatePicker, Button } from "antd";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import Select from "../components/common/Select";
import Input from "../components/common/Input";
import { pastTripColumns } from "../configs/tableConfig";
import { paginate } from "../utils/paginate";
import { pastTripData } from "../services/fakePastTripService";
import "../styles/main.css";
import { CSVLink } from "react-csv";
import currency from "currency.js";
import obj from "../constants/Colors";
import { toast } from "react-toastify";

const { RangePicker } = DatePicker;

const PastTrips = (props) => {
  const [data, setData] = useState(
    props.past_trips.map((trip) => {
      const newTrip = {
        id: `${trip._id}`,
        details: `From ${trip.start_address} to ${trip.end_address}`,
        amount: currency(trip.cost_id?.price).format({ symbol: "₦" }),
        date: moment(`${trip.start_time}`).format("DD/MM/YYYY"),
        start_time: trip.start_time,
        status: trip.state.value === "FINISHED" ? "Paid" : "Declined",
        state: trip.state.value,
      };
      return newTrip;
    })
  );
  const [displayedData, setDisplayedData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);

  const getTripData = () => {
    props
      .getPastTrips()
      .then((res) => {
        let newTrips = [];

        if (res.payload.data.trips) {
          res.payload.data.trips.forEach((trip) => {
            const newTrip = {
              id: `${trip._id}`,
              details: `From ${trip.start_address} to ${trip.end_address}`,
              amount: currency(trip.cost_id?.price).format({ symbol: "₦" }),
              date: moment(`${trip.start_time}`).format("DD/MM/YYYY"),
              start_time: trip.start_time,
              status: trip.state.value === "FINISHED" ? "Finished" : "Cancelled",
              state: trip.state.value,
            };
            newTrips.push(newTrip);
          });

          setData([...newTrips]);
        }
      })
      .catch((error) => {
        // toast.error('Error getting past trip data!');
        return error;
      });
  };

  useEffect(getTripData, []);

  useEffect(() => {
    const newData = data.filter((row) => {
      return row.details.toLowerCase().includes(searchInput.toLowerCase());
    });
    setPageNumber(1);
    searchInput ? setDisplayedData(newData) : setDisplayedData(data);

    return () => {
      setDisplayedData(data);
    };
  }, [searchInput]);

  const handleSearchChange = ({ currentTarget: input }) => {
    setSearchInput(input.value);
  };

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const handleDateChange = (date) => {
    if (date) {
      setStartDate(date[0]._d);
      setEndDate(date[1]._d);
      const newData = data.filter(
        (item) =>
          moment(item.start_time).diff(moment(date[0]._d), "days") >= 0 &&
          moment(item.start_time).diff(moment(date[1]._d), "days") <= 0
      );
      setDisplayedData(newData);
      setSearchInput("");
    } else {
      setDisplayedData(data);
    }
  };

  let newData = paginate(searchInput || endDate ? displayedData : data, pageNumber, 10);
  newData = newData.reverse();

  const headers = [
    { label: "ID", key: "id" },
    { label: "Amount", key: "amount" },
    { label: "Trip State", key: "state" },
    { label: "Date", key: "date" },
    { label: "Details", key: "details" },
  ];

  const csvReport = {
    filename: "TripHistory.csv",
    headers: headers,
    data: newData,
  };

  const EmptyIcon = <img src={Empty} alt="Empty list" style={{ width: "100px" }} />;

  return (
    <div>
      <h2 className="header">Trip History</h2>
      <div className="inner-container">
        <div className="past-trip-top" type="primary">
          <div className="filterContainer">
            <Input
              placeholder="Search"
              size="large"
              value={searchInput}
              className="past-trip-input"
              prefix={<SearchOutlined />}
              handleChange={handleSearchChange}
            />
            <RangePicker className="range-picker" onChange={handleDateChange} size="large" />
          </div>
          <CSVLink {...csvReport}>
            <Button className="all-button" type="primary" size={"large"}>
              Export CSV
            </Button>
          </CSVLink>
        </div>
        <div className="table-container">
          <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  props.history.push(`/app/trip-summary/${record.id}`);
                }, // click row
              };
            }}
            className="table"
            locale={{ emptyText: EmptyIcon }}
            columns={pastTripColumns}
            dataSource={newData}
            size="large"
            pagination={false}
          />
          <Pagination
            current={pageNumber}
            onChange={handlePageChange}
            total={displayedData.length > 0 ? displayedData.length : data.length}
            style={{ alignSelf: "end" }}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ user, trips, past_trips }) => ({ user, trips, past_trips });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(PastTrips);
