import React, {useEffect, useState} from 'react';
import {Button, Table} from 'antd';
import Empty from '../assets/svgs/empty.svg'
import { paymentHistoryCol, paymentHistoryColCompany } from '../configs/tableConfig';
import { paymentHistoryArray } from '../services/fakePaymentHis'
import { connect } from 'react-redux';
import { reduxFunctions} from "../helper/ReduxHelper";
import '../styles/main.css'
import moment from "moment";
import currency from "currency.js";
import {toast} from "react-toastify";




const PaymentHistoryScreen = (props) => {
    const [data, setData] = useState(
        props.paymentHistory.map(payment => {
        const newPayment = {
            id: payment._id,
            details: `From ${payment.start_address} to ${payment.end_address}`,
            distance: payment.distance,
            amount: currency(payment.price).format({symbol: '₦'}),
            date: moment(payment.end_time).format('DD MMMM YYYY'),
            driver: payment.driver?.full_name
        }
        return newPayment
    }))
    const [user, setUser] = useState(props.user);

    const getPaymentHistory = async() => {
        await props.getPaymentHistory().then(res => {
            const newData = res.payload.data.payments.map(payment => {
                const newPayment = {
                    id: payment._id,
                    details: `From ${payment.start_address} to ${payment.end_address}`,
                    distance: `${payment.distance}KM`,
                    amount: currency(payment.price).format({symbol: '₦'}),
                    date: moment(payment.end_time).format('DD MMMM YYYY'),
                    driver: payment.driver?.full_name
                }
                return newPayment
            });
            setData(newData);
        }).catch(error => {
            // toast.error('Error getting payment history!');
            return error;
        })
    }
    const getUser = () => {
        props.getMe().then(res => {
            setUser(res.payload.data.user)
        }).catch(error => {
            return error;
        })
    }

    useEffect(getPaymentHistory, []);
    useEffect(getUser, [])

    const EmptyIcon = <img src={Empty} alt='Empty list' style={{ width: '100px'}}/>

    return (
        <div>
            <h2 className='header'>Payment History</h2>
            <div className='inner-container payment-his'>
                <div className='table-container'>
                    <Table className="table" locale={{emptyText: EmptyIcon }} columns={user.user_type === 'company'? paymentHistoryColCompany : paymentHistoryCol} dataSource={data} size="large"  pagination={false}/>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({user, paymentHistory}) =>({user, paymentHistory});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistoryScreen);

